import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { ApiService } from '../../services/api/api.service';
var RecuperarClaveComponent = /** @class */ (function () {
    function RecuperarClaveComponent(formBuilder, loginService, route, _api) {
        this.formBuilder = formBuilder;
        this.loginService = loginService;
        this.route = route;
        this._api = _api;
        this.isLoading = false;
    }
    RecuperarClaveComponent.prototype.ngOnInit = function () {
        this.loadForm();
    };
    RecuperarClaveComponent.prototype.loadForm = function () {
        this.formData = this.formBuilder.group({
            user: ['', [Validators.required, Validators.email]],
        });
    };
    RecuperarClaveComponent.prototype.send = function () {
        var _this = this;
        this.isLoading = true;
        var email = this.formData.value.user;
        this._api.forgotPassword(email).subscribe(function (data) {
            _this.msg = data.msg;
            _this.isLoading = false;
        });
    };
    return RecuperarClaveComponent;
}());
export { RecuperarClaveComponent };
