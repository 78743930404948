import { EventEmitter } from '@angular/core';
var IncrementadorComponent = /** @class */ (function () {
    function IncrementadorComponent() {
        this.leyenda = 'leyenda';
        this.progress = 50;
        this.changeProgress = new EventEmitter();
        console.log('Leyenda', this.leyenda);
        console.log('Progreso', this.progress);
    }
    IncrementadorComponent.prototype.ngOnInit = function () {
    };
    IncrementadorComponent.prototype.barra = function (number) {
        if (this.progress >= 100) {
            this.progress = 100;
            return;
        }
        if (this.progress <= 0) {
            this.progress = 0;
            return;
        }
        this.progress = this.progress + number;
        this.changeProgress.emit(this.progress);
    };
    return IncrementadorComponent;
}());
export { IncrementadorComponent };
