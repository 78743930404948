<!-- ============================================================== -->
<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <!-- Logo icon --><b>
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Dark Logo icon -->
          <img
            src="assets/images/icon-blue.png"
            alt="homepage"
            class="dark-logo"
          />
          <!-- Light Logo icon -->
          <img
            src="assets/images/logo-light-icon.png"
            alt="homepage"
            class="light-logo"
          />
        </b>
                <!--End Logo icon -->
                <!-- Logo text --><span>
          <!-- dark Logo text -->
          <img
            src="assets/images/logo-text.png"
            alt="homepage"
            class="dark-logo" />
          <!-- Light Logo text -->
          <img
            src="assets/images/logo-light-text.png"
            class="light-logo"
            alt="homepage"
        /></span>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto">
                <!-- This is  -->
                <li class="nav-item">
                    <a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i
          ></a>
                </li>
                <li class="nav-item">
                    <a class="
              nav-link
              sidebartoggler
              hidden-sm-down
              waves-effect waves-dark
            " href="javascript:void(0)"><i class="ti-menu"></i
          ></a>
                </li>
                <li class="nav-item hidden-sm-down"></li>
            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <!-- ============================================================== -->
                <!-- Search -->
                <!-- ============================================================== -->
                <li class="nav-item hidden-xs-down search-box">
                    <a class="nav-link hidden-sm-down waves-effect waves-dark" href="javascript:void(0)"><i class="ti-search"></i
          ></a>
                    <form class="app-search">
                        <input type="text" class="form-control" placeholder="Search & enter" />
                        <a class="srh-btn"><i class="ti-close"></i></a>
                    </form>
                </li>
                <!-- ============================================================== -->
                <!-- Comment -->
                <!--mdi mdi-message-->
                <!-- ==============================================================-->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="mdi mdi-bell"></i>
                        <div class="notify">
                            <span class="heartbit"></span> <span class="point"></span>
                        </div>
                    </a>
                    <div class="
                        dropdown-menu dropdown-menu-right
                        mailbox
                        mailboxBell
                        animated
                        bounceInDown
                        ">
                        <ul>
                            <li>
                                <div class="drop-title">NOTIFICACIONES</div>
                            </li>
                            <li>
                                <div class="message-center">
                                    <a href="#">
                                        <img src="assets/images/users/1.jpg" alt="user" class="profile-pic2 profile-pic" />
                                        <!--
                    <div class="btn btn-danger btn-circle">
                      <i class="fa fa-link"></i>
                    </div>
                      -->
                                        <div class="mail-contnet">
                                            <span class="nServicio">El chofer descargo producto en punto A</span
                      >
                      <span class="mail-desc">24/07/20 16:00:00 </span>
                                            <span class="nServicio">Numero de Servicio:3458965</span>
                                        </div>
                                    </a>

                                    <a href="#">
                                        <img src="assets/images/users/2.jpg" alt="user" class="profile-pic2 profile-pic" />
                                        <!--
                     <div class="btn btn-success btn-circle">
                      <i class="ti-calendar"></i>
                    </div>
                  -->
                                        <div class="mail-contnet">
                                            <span class="nServicio">El chofer descargo producto en punto B</span
                      >
                      <span class="mail-desc">24/07/20 16:00:00 </span>
                                            <span class="nServicio">Numero de Servicio:3458965</span>
                                        </div>
                                    </a>
                                    <!--
                                    <a href="#">
                                        <div class="btn btn-info btn-circle"><i class="ti-settings"></i></div>
                                        <div class="mail-contnet">
                                            <h5>Settings</h5> <span class="mail-desc">You can customize this template as you want</span> <span class="time">9:08 AM</span> </div>
                                    </a>
                                    
                                    <a href="#">
                                        <div class="btn btn-primary btn-circle"><i class="ti-user"></i></div>
                                        <div class="mail-contnet">
                                            <h5>Pavan kumar</h5> <span class="mail-desc">Just see the my admin!</span> <span class="time">9:02 AM</span> </div>
                                    </a>
                                    -->
                                </div>
                            </li>
                            <!--<li>
                                <a class="nav-link text-center" href="javascript:void(0);"> <strong>Check all notifications</strong> <i class="fa fa-angle-right"></i> </a>
                            </li>-->
                        </ul>
                    </div>
                </li>
                <!-- ============================================================== -->
                <!-- End Comment -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Messages -->
                <!-- ============================================================== 
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" id="2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="mdi mdi-email"></i>
                        <div class="notify"> <span class="heartbit"></span> <span class="point"></span> </div>
                    </a>
                    <div class="dropdown-menu mailbox dropdown-menu-right animated bounceInDown" aria-labelledby="2">
                        <ul>
                            <li>
                                <div class="drop-title">You have 4 new messages</div>
                            </li>
                            <li>
                                <div class="message-center">
                                   
                                    <a href="#">
                                        <div class="user-img"> <img src="assets/images/users/1.jpg" alt="user" class="img-circle"> <span class="profile-status online pull-right"></span> </div>
                                        <div class="mail-contnet">
                                            <h5>Pavan kumar</h5> <span class="mail-desc">Just see the my admin!</span> <span class="time">9:30 AM</span> </div>
                                    </a>
                                  
                                    <a href="#">
                                        <div class="user-img"> <img src="assets/images/users/2.jpg" alt="user" class="img-circle"> <span class="profile-status busy pull-right"></span> </div>
                                        <div class="mail-contnet">
                                            <h5>Sonu Nigam</h5> <span class="mail-desc">I've sung a song! See you at</span> <span class="time">9:10 AM</span> </div>
                                    </a>
                                    
                                    <a href="#">
                                        <div class="user-img"> <img src="assets/images/users/3.jpg" alt="user" class="img-circle"> <span class="profile-status away pull-right"></span> </div>
                                        <div class="mail-contnet">
                                            <h5>Arijit Sinh</h5> <span class="mail-desc">I am a singer!</span> <span class="time">9:08 AM</span> </div>
                                    </a>
                                    
                                    <a href="#">
                                        <div class="user-img"> <img src="assets/images/users/4.jpg" alt="user" class="img-circle"> <span class="profile-status offline pull-right"></span> </div>
                                        <div class="mail-contnet">
                                            <h5>Pavan kumar</h5> <span class="mail-desc">Just see the my admin!</span> <span class="time">9:02 AM</span> </div>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a class="nav-link text-center" href="javascript:void(0);"> <strong>See all e-Mails</strong> <i class="fa fa-angle-right"></i> </a>
                            </li>
                        </ul>
                    </div>
                </li>-->
                <!-- ============================================================== -->
                <!-- End Messages -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- mega menu -->
                <!-- ============================================================== 
                <li class="nav-item dropdown mega-dropdown"> <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="mdi mdi-view-grid"></i></a>
                    <div class="dropdown-menu animated bounceInDown">
                        <ul class="mega-dropdown-menu row">
                            <li class="col-lg-3 col-xlg-2 m-b-30">
                                <h4 class="m-b-20">CAROUSEL</h4>
                                
                                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                    <div class="carousel-inner" role="listbox">
                                        <div class="carousel-item active">
                                            <div class="container"> <img class="d-block img-fluid" src="assets/images/big/img1.jpg" alt="First slide"></div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="container"><img class="d-block img-fluid" src="assets/images/big/img2.jpg" alt="Second slide"></div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="container"><img class="d-block img-fluid" src="assets/images/big/img3.jpg" alt="Third slide"></div>
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a>
                                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span> </a>
                                </div>
                               
                            </li>
                            <li class="col-lg-3 m-b-30">
                                <h4 class="m-b-20">ACCORDION</h4>
                               
                                <div id="accordion" class="nav-accordion" role="tablist" aria-multiselectable="true">
                                    <div class="card">
                                        <div class="card-header" role="tab" id="headingOne">
                                            <h5 class="mb-0">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                  Collapsible Group Item #1
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
                                            <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high. </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" role="tab" id="headingTwo">
                                            <h5 class="mb-0">
                                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                  Collapsible Group Item #2
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo">
                                            <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" role="tab" id="headingThree">
                                            <h5 class="mb-0">
                                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                  Collapsible Group Item #3
                                                </a>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree">
                                            <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="col-lg-3  m-b-30">
                                <h4 class="m-b-20">CONTACT US</h4>
                                
                                <form>
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="exampleInputname1" placeholder="Enter Name"> </div>
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Enter email"> </div>
                                    <div class="form-group">
                                        <textarea class="form-control" id="exampleTextarea" rows="3" placeholder="Message"></textarea>
                                    </div>
                                    <button type="submit" class="btn btn-info">Submit</button>
                                </form>
                            </li>
                            <li class="col-lg-3 col-xlg-4 m-b-30">
                                <h4 class="m-b-20">List style</h4>
                              
                                <ul class="list-style-none">
                                    <li><a href="javascript:void(0)"><i class="fa fa-check text-success"></i> You can give link</a></li>
                                    <li><a href="javascript:void(0)"><i class="fa fa-check text-success"></i> Give link</a></li>
                                    <li><a href="javascript:void(0)"><i class="fa fa-check text-success"></i> Another Give link</a></li>
                                    <li><a href="javascript:void(0)"><i class="fa fa-check text-success"></i> Forth link</a></li>
                                    <li><a href="javascript:void(0)"><i class="fa fa-check text-success"></i> Another fifth link</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </li>-->
                <!-- ============================================================== -->
                <!-- End mega menu -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Language -->
                <!-- ==============================================================-->
                <!--
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle waves-effect waves-dark"
            href=""
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-bell"></i
          ></a>
          <div class="dropdown-menu dropdown-menu-right animated bounceInDown">
            <a class="dropdown-item" href="#"
              ><i class="flag-icon flag-icon-in"></i> India</a
            >
            <a class="dropdown-item" href="#"
              ><i class="flag-icon flag-icon-fr"></i> French</a
            >
            <a class="dropdown-item" href="#"
              ><i class="flag-icon flag-icon-cn"></i> China</a
            >
            <a class="dropdown-item" href="#"
              ><i class="flag-icon flag-icon-de"></i> Dutch</a
            >
          </div>
        </li>
      -->
                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="ImgPerfil">
                            <!-- <i class="mdi mdi-account-circle iconoPerfil"></i> -->
                            <img [src]="user.url_foto_perfil | image_user" style="border-radius: 50%;"/>
                        </div>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right animated flipInY">
                        <ul class="dropdown-user ulHeadera">
                            <li>
                                <div class="dw-user-box d-flex">
                                    <!-- <div class="ImgPerfil2" *ngIf="user.url_foto_perfil === null">
                                        <i class="mdi mdi-account iconoPerfil2"></i>
                                    </div> -->
                                    <div class="ImgPerfil2">
                                        <img [src]="user.url_foto_perfil | image_user" style="border-radius: 50%;"/>
                                    </div>
                                    <div class="u-text textPerfil">
                                        <h4 *ngIf="user.nombres">{{ user.nombres }} {{ user.apellidos }}</h4>
                                        <h4 *ngIf="user.nombre_empresa">{{ user.nombre_empresa }} </h4>
                                    </div>
                                </div>
                            </li>
                            <li role="separator" class="divider ml-3"></li>
                            <li class="ml-3">
                                <a routerLink="/profile"><i class="ti-user iconoBlueheader"></i> Perfil</a
                >
              </li>
              <li class="ml-3 mt-1" *ngIf="role === 'Cliente' || role === 'Empresa'">
                <a routerLink="/wallet/method-payment"
                  ><i class="ti-wallet iconoBlueheader"></i> Billetera</a
                >
              </li>
              <li class="ml-3 mt-1" *ngIf="role === 'Administrador'">
                <a routerLink="/profile"
                  ><i class="fa fa-sliders iconoBlueheader"></i>
                  Configuracion</a
                >
              </li>
              <li class="ml-3 mt-1">
                <a href="#" (click)="logOut()"
                  ><i class="fa fa-power-off iconoBlueheader"></i> Salir</a
                >
              </li>

              <li role="separator" class="divider"></li>
              <li class="ml-3 relleno" *ngIf="role === 'Administrador'"></li>
              <li class="ml-3 mt-1" *ngIf="role === 'Cliente' || role === 'Empresa'">
                <a
                  href="https://share.hsforms.com/1e4e4ZxKMRPaWm3ErJnXQwQ3a7g6"
                  target="blank"
                >
                  <i class="mdi mdi-headphones iconoBlueheader"></i>
                  ¿Nececita ayuda?
                </a>
                            </li>
                            <!--
              <li role="separator" class="divider"></li>
              <li class="ml-3 mt-1">
                <a href="#" (click)="logOut()"
                  ><i class="fa fa-power-off iconoBlueheader"></i> Salir</a
                >
              </li>
            -->
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
<!-- ============================================================== -->
<!-- End Topbar header -->
<!-- ============================================================== -->

