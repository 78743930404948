<div class="row cont-login login-user ">
  <div class="col-md-6 pd-forms-login text-center no-display-movil">
    <img class="img-80" src="../../assets/images/logo-white2.png" />

    <h1 class="color-white"><b style="font-size: 37px">BIENVENIDO</b></h1>
    <p class="color-white p-login">
      Te damos la bienvenida a la mejor red para solicitar servicios de
      acarreos, mudanzas y logística en Panamá. Ingresa y disfruta de las
      soluciones que ofrece Kamgus
    </p>
  </div>
  <div class="col-md-6 cont-white pd-forms-login contenedor1">
    <form
      class="form-horizontal form-material"
      [formGroup]="formData" *ngIf="formData"
    >
     
      <h1 class="text-center-ingresar">Recuperar Contraseña</h1>

      <div *ngIf="warning" class="text-center alert alert-danger">
        {{ warning }}
      </div>

      <br />
      
      <div class="form-group">
        <!--<div class="col-xs-12 | centrado-inputs">-->
          <div>
            <label for="" class="label-login">Nueva contraseña </label>
            <input
              class="form-control"
              type="password"
              name="password"
              required="true"
              placeholder="contraseña"
              formControlName="password"
              value=""
            />
        </div>
        <br>
        <div>
            <label for="" class="label-login">Confirmar contraseña </label>
            <input
              class="form-control"
              type="password"
              name="retypePassword"
              required="true"
              placeholder="confirmar contraseña"
              formControlName="retypePassword"
              (keyup)="onKey($event)"
              value=""
            />
        </div>
        <div>
          <span *ngIf="!isEqual" class="form-text text-danger">
            Las contraseñas no coinciden.
          </span>
        </div>
        
        <!--
        <div *ngIf="submitted && f.user.errors" class="invalid-feedback">
          <div *ngIf="f.user.errors.required">* El email es requerido</div>
        </div>
        <div *ngIf="submitted && f.user.errors" class="invalid-feedback">
          <div *ngIf="f.user.errors.email">
            * No es un formato de correo válido
          </div>
        </div>
      -->
      </div>
      
      
      <div class="form-group text-center contenedorbtnLogin">
        <div class="botonlogin">
          <button
            class="btn btnkamgus backnaranja"
            type="submit"
            (click)="send()"
            [disabled]="isLoading"
          >
            Cambiar
            <div *ngIf="isLoading" class="lds-dual-ring"></div>
          </button>
        </div>
       
      </div>
    </form>
  </div>
</div>
