import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { tap } from "rxjs/operators";
import { User } from '../../interfaces/UserInterface';

import { environment } from "../../../environments/environment";
const apikamgus_base_url = environment.apikamgus_base_url;
@Injectable()
export class SharedService {

  constructor( 
    private toastr: ToastrService, 
    public http: HttpClient ) { }

  decodeJwt() {
    const token = localStorage.getItem('token');
    const decoded = jwt_decode(token);
    return decoded;
  }

  getRole(){
    const role = JSON.parse(localStorage.getItem('role'));
    return role;
  }

  calculatePriceByVehicule( totalTime, precio_minuto, precio_ayudante, tiempo ) {
    // tslint:disable-next-line: radix
    const timeMaps = Math.round( ( parseInt ( totalTime ) / 60) * 2);
    precio_minuto = Math.round(timeMaps * precio_minuto) + parseInt(precio_ayudante) + tiempo * 60 * precio_minuto;
    return precio_minuto;
  }

  get token(): string {
    return 'Bearer ' + JSON.parse(localStorage.getItem('token')) || '';
  }

  get headers() {
    return {
      headers: {
        'Authorization': this.token
      }
    }
  }

  showSuccess(msg: string) {
    this.toastr.success( msg, '¡Exitoso!', {
      timeOut: 2000,
    });
  }
  showError(msg: string) {
    this.toastr.error(msg, 'Error',  {
      timeOut: 2000,
    });
  }

  showUser() {

    const url = `${apikamgus_base_url}/user`;

    return this.http.get<User>(url, this.headers).pipe(
      tap((resp) => {
        return resp;
      })
    );

  }

}
