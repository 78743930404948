import { ApiService } from './../../services/api/api.service';
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
var DeleteAccountComponent = /** @class */ (function () {
    function DeleteAccountComponent(formBuilder, _api, router) {
        this.formBuilder = formBuilder;
        this._api = _api;
        this.router = router;
        this.emailRegistrado = false;
        this.submitted = false;
        this.isLoading = false;
        this.typeUser = "user";
    }
    DeleteAccountComponent.prototype.ngOnInit = function () {
        this.loadForm();
    };
    DeleteAccountComponent.prototype.loadForm = function () {
        this.formData = this.formBuilder.group({
            email: ["", [Validators.required]],
            password: ["", [Validators.required]],
        });
    };
    DeleteAccountComponent.prototype.inputValidator = function (e) {
    };
    DeleteAccountComponent.prototype.clickDeleteAccount = function () {
        if (!confirm("¿Esta segura de eliminar esta cuenta?")) {
            // user clicked OK
            return;
        }
        this.submitted = true;
        //alert(this.formData.value.email+" : "+this.formData.value.password);
        this._api.deleteAccount(this.formData.value).subscribe(function (data) {
            alert("Su cuenta fue eliminada exitosamente");
        }, function (err) {
            debugger;
            alert("Error:  \n" + err.error.msg);
        });
    };
    Object.defineProperty(DeleteAccountComponent.prototype, "f", {
        get: function () {
            return this.formData.controls;
        },
        enumerable: true,
        configurable: true
    });
    return DeleteAccountComponent;
}());
export { DeleteAccountComponent };
