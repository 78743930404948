import { Router } from '@angular/router';
var StorageService = /** @class */ (function () {
    function StorageService(router) {
        this.router = router;
    }
    StorageService.prototype.saveLogin = function (token, licence) {
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('licence', licence);
    };
    StorageService.prototype.saveLoginNew = function (token) {
        localStorage.setItem('token', JSON.stringify(token));
    };
    StorageService.prototype.logOut = function () {
        localStorage.clear();
        this.router.navigate(['/login']);
    };
    return StorageService;
}());
export { StorageService };
