import { environment } from '../../../environments/environment';
var ThankyouPageComponent = /** @class */ (function () {
    function ThankyouPageComponent() {
    }
    ThankyouPageComponent.prototype.ngOnInit = function () {
        this.web = environment.web;
        this.google = environment.googleDriver;
        this.ios = environment.iosDriver;
    };
    return ThankyouPageComponent;
}());
export { ThankyouPageComponent };
