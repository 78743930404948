<div class="row cont-login login-user">
  <div class="col-md-6 pd-forms-login text-center no-display-movil">
    <img class="img-80" src="../../assets/images/logo-white2.png" />
    <!-- <div class="spacer-100"></div>-->
    <h1 class="color-white"><b style="font-size: 37px">BIENVENIDO</b></h1>
    <p class="color-white p-login">
      Te damos la bienvenida a la mejor red para solicitar servicios de
      acarreos, mudanzas y logística en Panamá. Ingresa y disfruta de las
      soluciones que ofrece Kamgus
    </p>
  </div>
  <div class="col-md-6 cont-white pd-forms-login">
    
  </div>
</div>
<!--
  <div class="botonlogin btnkamgus">
        <button
          class="btn btn-block btn-lg btn-warning btn-rounded btnLoginKamgus"
          type="submit"
          (click)="login()"
        >
          Entrar a Kamgus
        </button>
      </div>
-->
