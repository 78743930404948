import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class EmmitersService {
  @Output() getCards: EventEmitter<boolean> = new EventEmitter();
  reload = false;
  constructor() { }
  reloaadGetCards() {
    console.log( 'servicio');
    this.reload = !this.reload;
    this.getCards.emit( this.reload );
  }
}
