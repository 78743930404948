import { EventEmitter } from '@angular/core';
var EmmitersService = /** @class */ (function () {
    function EmmitersService() {
        this.getCards = new EventEmitter();
        this.reload = false;
    }
    EmmitersService.prototype.reloaadGetCards = function () {
        console.log('servicio');
        this.reload = !this.reload;
        this.getCards.emit(this.reload);
    };
    return EmmitersService;
}());
export { EmmitersService };
