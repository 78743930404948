/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ng2-charts";
import * as i2 from "./graficos.component";
var styles_GraficosComponent = [];
var RenderType_GraficosComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GraficosComponent, data: {} });
export { RenderType_GraficosComponent as RenderType_GraficosComponent };
export function View_GraficosComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["style", "display: block"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [["class", "title-card"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵted(-1, null, ["\n\n\n    "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "canvas", [["baseChart", ""]], null, null, null, null, null)), i0.ɵdid(6, 737280, null, 0, i1.BaseChartDirective, [i0.ElementRef], { data: [0, "data"], labels: [1, "labels"], chartType: [2, "chartType"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.doughnutChartData; var currVal_2 = _co.doughnutChartLabels; var currVal_3 = _co.doughnutChartType; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.leyenda; _ck(_v, 3, 0, currVal_0); }); }
export function View_GraficosComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-graficos", [], null, null, null, View_GraficosComponent_0, RenderType_GraficosComponent)), i0.ɵdid(1, 114688, null, 0, i2.GraficosComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GraficosComponentNgFactory = i0.ɵccf("app-graficos", i2.GraficosComponent, View_GraficosComponent_Host_0, { doughnutChartLabels: "doughnutChartLabels", doughnutChartData: "doughnutChartData", doughnutChartType: "doughnutChartType", leyenda: "leyenda" }, {}, []);
export { GraficosComponentNgFactory as GraficosComponentNgFactory };
