import { Routes, RouterModule } from "@angular/router";

import { PagesComponent } from "./pages.component";
import { ProgressComponent } from "./progress/progress.component";
import { Graficas1Component } from "./graficas1/graficas1.component";
import { AccountSettingsComponent } from "./account-settings/account-settings.component";
import { AuthGuard } from "../guards/auth.guard";
import { BuyLicenceComponent } from "./buy-licence/buy-licence.component";
import { LicenceGuard } from "../guards/licence.guard";
import { Modules } from "../enums/modules.enum";
import { AdminGuard } from "../guards/admin.guard";
//import { AdminModule } from '../pages/admin/admin.module';

const pagesRoutes: Routes = [
  {
    path: "",
    component: PagesComponent,
    children: [
      {
        path: "progress",
        component: ProgressComponent,
        canActivate: [AuthGuard],
        data: { breadcumbs: "Progreso" },
      },
      {
        path: "graficas1",
        component: Graficas1Component,
        canActivate: [AuthGuard],
        data: { breadcumbs: "Graficas" },
      },
      {
        path: "account-settings",
        component: AccountSettingsComponent,
        canActivate: [AuthGuard],
        data: { breadcumbs: "Cuenta" },
      },
      {
        path: "buy-licence",
        component: BuyLicenceComponent,
        canActivate: [AuthGuard],
        data: { breadcumbs: "Licencia" },
      },
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
    ],
  },

  {
    path: "dashboard",
    component: PagesComponent,
    loadChildren: "../pages/dashboard/dashboard.module#DashboardModule",
    canActivate: [AuthGuard],
    data: { breadcumbs: "" },
  },
  {
    path: "wallet",
    component: PagesComponent,
    loadChildren: "../pages/wallet/wallet.module#WalletModule",
    canActivate: [AuthGuard],
    // LicenceGuard
    data: { breadcumbs: "Billetera", module: Modules.Wallet },
  },
  {
    path: "admin",
    component: PagesComponent,
    loadChildren: "../pages/admin/admin.module#AdminModule",
    // loadChildren: () => AdminModule,
    canActivate: [AuthGuard],
    data: { breadcumbs: "Administración" },
  },
  {
    path: "history",
    component: PagesComponent,
    loadChildren: "../pages/history/history.module#HistoryModule",
    canActivate: [AuthGuard],
    // , LicenceGuard
    data: { breadcumbs: "Historial", module: Modules.History },
  },
  {
    path: "payment-center",
    component: PagesComponent,
    loadChildren:
      "../pages/payment-center/payment-center.module#PaymentCenterModule",
    canActivate: [AuthGuard, LicenceGuard],
    data: { breadcumbs: "Centro de pagos", module: Modules.Payment },
  },
  {
    path: "profile",
    component: PagesComponent,
    loadChildren: "../pages/profile/profile.module#ProfileModule",
    canActivate: [AuthGuard],
    data: { breadcumbs: "Perfil" },
  },
  {
    path: "referrals",
    component: PagesComponent,
    loadChildren: "../pages/referrals/referrals.module#ReferralsModule",
    canActivate: [AuthGuard, LicenceGuard],
    data: { breadcumbs: "Referidos", module: Modules.Referrals },
  },
  {
    path: "store",
    component: PagesComponent,
    loadChildren: "../pages/store/store.module#StoreModule",
    canActivate: [AuthGuard, LicenceGuard],
    data: { breadcumbs: "Tienda", module: Modules.Store },
  },
  {
    path: "support",
    component: PagesComponent,
    loadChildren: "../pages/support/support.module#SupportModule",
    canActivate: [AuthGuard],
    data: { breadcumbs: "Soporte" },
  },
  {
    path: "massive-load",
    component: PagesComponent,
    loadChildren: "../pages/massive-load/masive-load.module#MasiveLoadModule",
    canActivate: [AuthGuard, LicenceGuard],
    data: { breadcumbs: "Carga masiva", module: Modules.Massive },
  },
  {
    path: "services",
    component: PagesComponent,
    loadChildren: "../pages/services/services.module#ServicesModule",
    canActivate: [AuthGuard],
    data: { breadcumbs: "Servicios" },
  },
  {
    path: "create-licence",
    component: PagesComponent,
    loadChildren: "../pages/licences/licences.module#LicencesModule",
    canActivate: [AuthGuard],
    data: { breadcumbs: "Licencias" },
  },
  {
    path: "users",
    component: PagesComponent,
    loadChildren: "../pages/users/users.module#UsersModule",
    canActivate: [AuthGuard, AdminGuard],
    data: { breadcumbs: "Usuarios" },
  },
];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
