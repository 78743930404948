import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class StorageService {

  constructor( private router: Router ) { }

  saveLogin( token , licence ) {
    localStorage.setItem( 'token', JSON.stringify( token ) );
    localStorage.setItem( 'licence', licence  );
  }

  saveLoginNew( token ) {
    localStorage.setItem( 'token', JSON.stringify( token ) );
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
