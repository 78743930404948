import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-thankyou-page-driver',
  templateUrl: './thankyou-page-driver.component.html',
  styleUrls: ['./thankyou-page-driver.component.css']
})
export class ThankyouPageDriverComponent implements OnInit {

  web;
  google;
  ios;
  constructor() { }

  ngOnInit() {
    this.web = environment.web;
    this.google = environment.google;
    this.ios = environment.ios;
  }

}
