import { Router } from "@angular/router";
import { SharedService } from "../../services/shared/shared.service";
import { SidebarService } from '../../services/shared/sidebar.service';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(router, sharedService, _sb) {
        this.router = router;
        this.sharedService = sharedService;
        this._sb = _sb;
        // this.rol = localStorage.getItem("rol");
    }
    HeaderComponent.prototype.ngOnInit = function () {
        if (localStorage.getItem("token")) {
            //this.user = this.sharedService.decodeJwt();
            // const token = localStorage.getItem("token");
            // this.usuario = jwt_decode(token);
            // console.log("usuario ----- ", this.usuario);
            this.user = {
                nombres: '',
                apellidos: '',
                nombre_empresa: '',
                nombre_contacto: '',
            };
            this.ShowUser();
        }
    };
    HeaderComponent.prototype.logOut = function () {
        localStorage.clear();
        // this._sb.menu = [];
        this.router.navigate(['/login']);
    };
    HeaderComponent.prototype.ShowUser = function () {
        var _this = this;
        this.sharedService.showUser().subscribe(function (user) {
            _this.user = user.data.user;
            _this.role = user.data.role;
        });
    };
    return HeaderComponent;
}());
export { HeaderComponent };
