import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {  Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { StorageService } from '../../services/storage/storage.service';
import { ToastrService } from 'ngx-toastr';


declare function init_plugins();
@Component({
  selector: 'app-login-company',
  templateUrl: './login-company.component.html',
  styleUrls: ['./login-company.component.css'],
  providers: [ LoginService ]
})
export class LoginCompanyComponent implements OnInit {

  formData: FormGroup;
  submitted = false;
  failedLogin = false;
  role: string;
  constructor( 
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private storageService: StorageService ,
    private route: Router,
    private toastr: ToastrService
    ) { }

  ngOnInit( ) {
    init_plugins();
    this.loadForm();
    if ( localStorage.getItem('user') || localStorage.getItem('user') !== null) {
      this.route.navigate(['/dashboard']);
    }
  }

  loadForm() {
    this.formData = this.formBuilder.group({
      user: [ '', [ Validators.required, Validators.email ] ],
      pass: [ '', [ Validators.required ] ]
    });
  }

  login() {
    this.submitted = true;
    if ( !this.formData.valid ) {
      return;
    }
    const params = new HttpParams()
      .set('email', this.formData.value.user )
      .set('password', this.formData.value.pass);

    this.loginService.loginNew( params ).subscribe( resp => {
      // this.failedLogin = false;
      // this.storageService.saveLogin( response['token'],
      // response['license'] && response['license'].licence ? response['license'].licence : null );
      // this.redirect();
      if(resp.success){
        this.toastr.success(resp.success, '', {
          timeOut: 3000,
          progressBar: true,
        });
        
        if (resp.role === 'Empresa') {
          this.route.navigate(["/services/point-to-point"]);
            // location.href = '/services/point-to-point';
        } else {
          this.route.navigate(["/dashboard"]);
            // location.href = '/dashboard';
            // location.reload();
        }

        // this.redirect();
      }else{
        this.toastr.error(resp.error, '', {
          timeOut: 3000,
          progressBar: true,
        });
      }


    }, err => {
      this.toastr.error(err, '', {
        timeOut: 3000,
        progressBar: true,
      });
      this.failedLogin = true;
    });
  }

  redirect() {
    if (this.role === 'Empresa') {
      this.route.navigate(["/services/point-to-point"]);
        // location.href = '/services/point-to-point';
    } else {
        this.route.navigate(["/dashboard"]);
        // location.href = '/dashboard';
        // location.reload();
    }
  }

  get f() { return this.formData.controls; }

}
