/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./account-settings.component";
import * as i2 from "@angular/platform-browser";
import * as i3 from "../../services/settings/settings.service";
var styles_AccountSettingsComponent = [];
var RenderType_AccountSettingsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AccountSettingsComponent, data: {} });
export { RenderType_AccountSettingsComponent as RenderType_AccountSettingsComponent };
export function View_AccountSettingsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 77, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(2, 0, null, null, 74, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\n        "])), (_l()(), i0.ɵeld(4, 0, null, null, 71, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵeld(6, 0, null, null, 68, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\n                "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "h4", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Temas"])), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵeld(11, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\n                "])), (_l()(), i0.ɵeld(13, 0, null, null, 60, "div", [["class", "r-panel-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵeld(15, 0, null, null, 57, "ul", [["class", "m-t-20"], ["id", "themecolors"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(17, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Con el sidebar claro"])), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(21, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, [["link1", 1]], null, 1, "a", [["class", "selector default-theme"], ["data-theme", "default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cambiarColor("default", i0.ɵnov(_v, 22)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["1"])), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(25, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, [["link2", 1]], null, 1, "a", [["class", "selector green-theme"], ["data-theme", "green"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cambiarColor("green", i0.ɵnov(_v, 26)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["2"])), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(29, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(30, 0, [["link3", 1]], null, 1, "a", [["class", "selector red-theme"], ["data-theme", "red"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cambiarColor("red", i0.ɵnov(_v, 30)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["3"])), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(33, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(34, 0, [["link4", 1]], null, 1, "a", [["class", "selector blue-theme"], ["data-theme", "blue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cambiarColor("blue", i0.ɵnov(_v, 34)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["4"])), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(37, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(38, 0, [["link5", 1]], null, 1, "a", [["class", "selector purple-theme"], ["data-theme", "purple"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cambiarColor("purple", i0.ɵnov(_v, 38)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["5"])), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(41, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(42, 0, [["link6", 1]], null, 1, "a", [["class", "selector megna-theme"], ["data-theme", "megna"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cambiarColor("megna", i0.ɵnov(_v, 42)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["6"])), (_l()(), i0.ɵted(-1, null, ["\n\n                        "])), (_l()(), i0.ɵeld(45, 0, null, null, 2, "li", [["class", "d-block m-t-30"]], null, null, null, null, null)), (_l()(), i0.ɵeld(46, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Con el sidebar oscuro"])), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(49, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(50, 0, [["link7", 1]], null, 1, "a", [["class", "selector default-dark-theme"], ["data-theme", "default-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cambiarColor("default-dark", i0.ɵnov(_v, 50)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["7"])), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(53, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(54, 0, [["link8", 1]], null, 1, "a", [["class", "selector green-dark-theme"], ["data-theme", "green-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cambiarColor("green-dark", i0.ɵnov(_v, 54)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["8"])), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(57, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(58, 0, [["link9", 1]], null, 1, "a", [["class", "selector red-dark-theme"], ["data-theme", "red-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cambiarColor("red-dark", i0.ɵnov(_v, 58)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["9"])), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(61, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(62, 0, [["link10", 1]], null, 1, "a", [["class", "selector blue-dark-theme"], ["data-theme", "blue-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cambiarColor("blue-dark", i0.ɵnov(_v, 62)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["10"])), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(65, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(66, 0, [["link11", 1]], null, 1, "a", [["class", "selector purple-dark-theme"], ["data-theme", "purple-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cambiarColor("purple-dark", i0.ɵnov(_v, 66)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["11"])), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(69, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(70, 0, [["link12", 1]], null, 1, "a", [["class", "selector megna-dark-theme"], ["data-theme", "megna-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cambiarColor("megna-dark", i0.ɵnov(_v, 70)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["12"])), (_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵted(-1, null, ["\n\n                "])), (_l()(), i0.ɵted(-1, null, ["\n\n            "])), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n\n\n    "])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
export function View_AccountSettingsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-account-settings", [], null, null, null, View_AccountSettingsComponent_0, RenderType_AccountSettingsComponent)), i0.ɵdid(1, 114688, null, 0, i1.AccountSettingsComponent, [i2.DOCUMENT, i3.SettingsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountSettingsComponentNgFactory = i0.ɵccf("app-account-settings", i1.AccountSettingsComponent, View_AccountSettingsComponent_Host_0, {}, {}, []);
export { AccountSettingsComponentNgFactory as AccountSettingsComponentNgFactory };
