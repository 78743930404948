import { HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Continue } from "../../../interfaces/ValidatorInterface";
//import * as bcrypt from "bcryptjs";
import { ApiService } from "../../../services/api/api.service";
import { LoginService } from "../../../services/login/login.service";
//import { UsuarioService } from '../../../services/Users/usuario.service';
import { StorageService } from "../../../services/storage/storage.service";
declare function init_plugins();

@Component({
  selector: "app-register-user",
  templateUrl: "./register-user.component.html",
  styleUrls: ["./register-user.component.css"],
  providers: [ApiService],
})
export class RegisterUserComponent implements OnInit {
  formData: FormGroup;
  formDataEmail: FormGroup;
  submitted = false;
  failedLogin = false;
  typeUser = "user";
  errorMessage;
  registerOK = false;
  licenseId = "1";
  step = 1;
  correos = [];
  rol: "2";
  emailRegistrado = false;
  telefonoRegistrado = false;
  isLoading:boolean = false;
  emailRegistradoUser = [];
  public paises: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    //private usuarioService: UsuarioService,
    private storageService: StorageService,
    private route: Router,
    private _api: ApiService,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    init_plugins();
    this.loadForm();
    if (localStorage.getItem("token") || localStorage.getItem("token") !== null) {
      this.route.navigate(["/dashboard"]);
    }
    this.validateLicence();
    this.getPaises();
  }

  validateLicence() {
    if (
      this.router.queryParams["_value"] &&
      this.router.queryParams["_value"].licenseKey
    ) {
      this.licenseId = this.router.queryParams["_value"].licenseKey;
    }
    this._api.licences().subscribe((lic) => {
      lic["licences"].forEach((element) => {
        if (element.id === this.licenseId) {
          this.licenseId = element.id;
        }
      });
    });
    console.log(this.licenseId);
  }

  /** Obtener paises para código telélefono */
  getPaises() {
    //this.loginService
    //  .paises(environment.apikamgus_base_url+"/countries/flags")
    this._api.genericGet("/countries/flags", "TOV2") 
      .subscribe(
        (respuesta: any[]) => {
          console.log("Listado Paises");
          console.log(respuesta);
          this.paises = respuesta;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  loadForm() {
    this.formData = this.formBuilder.group({
      nombre: ["", [Validators.required]],
      apellido: ["", [Validators.required]],
      celular: ["", [Validators.required]],
      documento_entidad: [0, [Validators.required]],
      terms: ["", [Validators.required]],
      company: ["", []],
      email: ["", [Validators.required, Validators.email]],
      reTypeEmail: ["", [Validators.required, Validators.email]],
    });
    this.formDataEmail = this.formBuilder.group({
      password: ["", [Validators.required]],
      retypePassword: ["", [Validators.required]],
    });
  }

  // send() {
  //   this.submitted = true;

  //   if (!this.formData.valid) {
  //     return;
  //   }
  //   const request = this.updateModel(this.formData.value);
  //   console.log(request);
  //   this.loginService.register(request).subscribe(
  //     (response) => {
  //       console.log("response", response);
  //       this.createLicense(response["id_user"]);
  //     },
  //     (err) => {
  //       console.log("err", err);
  //       this.errorMessage = err;
  //     }
  //   );
  //   console.log(this.formData.value);
  // }

  updateModel(form: any) {
    const params = new HttpParams()
      .set("email", this.formDataEmail.value.email)
      .set("nombre", this.typeUser === "company" ? form.company : form.nombre)
      .set("celular", form.celular)
      .set("documento_entidad", form.documento_entidad)
      .set("password", form.password)
      .set("rol", this.typeUser === "company" ? "5" : "2");
    return params;
  }

  get f() {
    return this.formData.controls;
  }

  get g() {
    return this.formDataEmail.controls;
  }

  selectUser($event) {
    this.typeUser = $event;
    if (this.typeUser === "company") {
      this.formData.get("company").setValidators(Validators.required);
      this.formData.get("company").updateValueAndValidity();
    } else {
      this.formData.get("company").clearValidators();
      this.formData.get("company").updateValueAndValidity();
    }
  }

  // createLicense(id_user: string) {
  //   const params = new HttpParams()
  //     .set("id_user", id_user)
  //     .set("id_licence", this.licenseId);
  //   this._api.activateFreeLicence(params).subscribe(
  //     (licence) => {
  //       this.createCustomer(id_user);
  //       this.registerOK = true;
  //       this.route.navigate(["/thankyou-page-user"]);
  //     },
  //     (err) => {
  //       this.errorMessage = err;
  //     }
  //   );
  // }

  createCustomer() {
    this.isLoading = true;
    const password = this.formDataEmail.value.password;

    const params = new HttpParams()
      .set("nombres", this.formData.value.nombre)
      .set("apellidos", this.formData.value.apellido)
      .set("telefono", this.formData.value.celular)
      .set("email", this.formData.value.email)
      .set("password", password)
      .set("password_confirmation",  password);

    this._api.registerCustomer(params).subscribe(
      (data) => {
        // console.log(data);
        this.isLoading = false;
        this.registerOK = true;
        this.route.navigate(["/thankyou-page-user"]);
      },
      (err) => {
        console.log(err);
      }
    );

  }
  /*API NUEVO*/
  // crearUsuario() {
  //   this.submitted = true;

  //   //const password = this.formDataEmail.value.password;
  //   //let passwordHash = bcrypt.hashSync(password, 10);
  //   this.loginService
  //     .crearUsuario({
  //       RegistroForm: {
  //         nombre: this.formData.value.nombre,
  //         apellidos: this.formData.value.apellido,
  //         celular: this.formData.value.celular,
  //         password: this.formDataEmail.value.password,
  //         email: this.formData.value.email,
  //         pais_id: "176",
  //         documento_entidad: "",
  //         url_foto: "",
  //         licencia_foto: "",
  //         tipo_documento_id: "1",
  //         rol: this.typeUser === "company" ? "5" : "2",
  //       },
  //     })
  //     .subscribe(
  //       (respuesta) => {
  //         console.log("Usuario Creado");
  //         console.log(respuesta);
  //         this.registerOK = true;
  //         this.route.navigate(["/thankyou-page-user"]);
  //       },
  //       (err) => {
  //         console.log(err);
  //         this.emailRegistrado = true;
  //       }
  //     );

  //   /*    this.loginService
  //     .crearUsuario("https://apiv2.kamgus.com/api/v2/registration/customers", {
  //       customer: {
  //         nombres: this.formData.value.nombre,
  //         apellidos: this.formData.value.apellido,
  //         telefono: this.formData.value.celular,
  //       },
  //       user: {
  //         email: this.formDataEmail.value.email,
  //         password: passwordHash,
  //       },
  //     })
  //     .subscribe(
  //       (respuesta) => {
  //         console.log("Usuario Creado");
  //         this.registerOK = true;
  //         this.route.navigate(["/thankyou-page-user"]);
  //       },
  //       (err) => {
  //         console.warn(err);
  //         this.emailRegistrado = true;
  //       }
  //     );*/
  // }

  next(step) {
    //console.log( this.formData );
    this.isLoading = true;
    const params = new HttpParams()
    .set("email", this.formData.value.email)
    .set("telefono", this.formData.value.celular)

    this.submitted = true;
    if (step === 1) {
      if (!this.formData.valid) {
        this.isLoading = false;
        return;
      }
      /**** Agregado */
      this._api
        .validateUser(params)
        .subscribe(
          (data: Continue) => {
            this.step += 1;
            this.isLoading = false;
          },
          (err: any) => {
            this.emailRegistrado = true;
            this.isLoading = false;
          }
        );
      /***** ---------------- */
      //this.step += 1; //Comentado
    }
    if (step === 2) {
      if (!this.formDataEmail.valid) {
        return;
      } else if (this.g.password.value !== this.g.retypePassword.value) {
        return;
      } else {
        this.createCustomer();
        //this.send();
      }
    }
  }

  inputValidator(event: any) {
    this.emailRegistrado = false;
  }
}

/*
const password = this.formData.value.password;
      let passwordHash = bcrypt.hash(password, 10);
      console.log(password);
      console.log(passwordHash);
      console.log(passwordHash._zone_symbol__value);


      this._api.genericGetNew('https://apiv2.kamgus.com/api/v2/users').subscribe( (users:any) => {
        this.correos = users.data;
        console.log(this.correos);
        this.emailRegistrado = this.correos.filter(e => e.email==this.formDataEmail.value.email)
        console.log(this.emailRegistrado);
      });

*/
