import { environment } from '../../../../src/environments/environment';
import { ToastrService } from 'ngx-toastr';
var StripeService = /** @class */ (function () {
    function StripeService(toastr) {
        this.toastr = toastr;
        this.pk = environment
            .pkStripe; //Production stripe key
    }
    StripeService.prototype.createSetupIntent = function (token) {
        var _this = this;
        var promise = new Promise(function (res, rej) {
            fetch(environment.apikamgus_base_url + environment.users.card.setup_intent, {
                method: 'POST',
                //body: JSON.stringify({user_id: token}),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer " + token,
                }
            }).then(function (res) { return res.json(); })
                .catch(function (error) {
                console.error('Error:', error);
                _this.toastr.error(JSON.stringify(error), "Error creando setup intent");
                rej("Error creando setup intent");
            })
                .then(function (response) {
                if (response.error && (response.error.code != 200)) {
                    _this.toastr.error(response.error.msg, "Error");
                    rej(response.error.msg);
                    return;
                }
                //this.presentModal(null, response.data.client_secret);
                res(response.data.client_secret);
            });
        });
        return promise;
    };
    return StripeService;
}());
export { StripeService };
