import { environment } from "../../environments/environment";
var base_url_profile_image = environment.base_url_profile_image;
var Image_userPipe = /** @class */ (function () {
    function Image_userPipe() {
    }
    Image_userPipe.prototype.transform = function (value) {
        if (value != null && value != "") {
            return base_url_profile_image + "/" + value;
        }
        else {
            return 'assets/icons/user_white.png';
        }
    };
    return Image_userPipe;
}());
export { Image_userPipe };
