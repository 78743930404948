import { HttpParams } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { StorageService } from '../../services/storage/storage.service';
import { ToastrService } from 'ngx-toastr';
var LoginCompanyComponent = /** @class */ (function () {
    function LoginCompanyComponent(formBuilder, loginService, storageService, route, toastr) {
        this.formBuilder = formBuilder;
        this.loginService = loginService;
        this.storageService = storageService;
        this.route = route;
        this.toastr = toastr;
        this.submitted = false;
        this.failedLogin = false;
    }
    LoginCompanyComponent.prototype.ngOnInit = function () {
        init_plugins();
        this.loadForm();
        if (localStorage.getItem('user') || localStorage.getItem('user') !== null) {
            this.route.navigate(['/dashboard']);
        }
    };
    LoginCompanyComponent.prototype.loadForm = function () {
        this.formData = this.formBuilder.group({
            user: ['', [Validators.required, Validators.email]],
            pass: ['', [Validators.required]]
        });
    };
    LoginCompanyComponent.prototype.login = function () {
        var _this = this;
        this.submitted = true;
        if (!this.formData.valid) {
            return;
        }
        var params = new HttpParams()
            .set('email', this.formData.value.user)
            .set('password', this.formData.value.pass);
        this.loginService.loginNew(params).subscribe(function (resp) {
            // this.failedLogin = false;
            // this.storageService.saveLogin( response['token'],
            // response['license'] && response['license'].licence ? response['license'].licence : null );
            // this.redirect();
            if (resp.success) {
                _this.toastr.success(resp.success, '', {
                    timeOut: 3000,
                    progressBar: true,
                });
                if (resp.role === 'Empresa') {
                    _this.route.navigate(["/services/point-to-point"]);
                    // location.href = '/services/point-to-point';
                }
                else {
                    _this.route.navigate(["/dashboard"]);
                    // location.href = '/dashboard';
                    // location.reload();
                }
                // this.redirect();
            }
            else {
                _this.toastr.error(resp.error, '', {
                    timeOut: 3000,
                    progressBar: true,
                });
            }
        }, function (err) {
            _this.toastr.error(err, '', {
                timeOut: 3000,
                progressBar: true,
            });
            _this.failedLogin = true;
        });
    };
    LoginCompanyComponent.prototype.redirect = function () {
        if (this.role === 'Empresa') {
            this.route.navigate(["/services/point-to-point"]);
            // location.href = '/services/point-to-point';
        }
        else {
            this.route.navigate(["/dashboard"]);
            // location.href = '/dashboard';
            // location.reload();
        }
    };
    Object.defineProperty(LoginCompanyComponent.prototype, "f", {
        get: function () { return this.formData.controls; },
        enumerable: true,
        configurable: true
    });
    return LoginCompanyComponent;
}());
export { LoginCompanyComponent };
