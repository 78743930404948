<div class="text-center pd-thank-you">
  <br><br>
  <h1 class="color-blue__titulothankscompany">GRACIAS POR SER PARTE <br> 
    DE KAMGUS</h1>
  <br>
  <img src="../../assets/images/thanks.png">
  <br>
  <p class="text-thankscompany textBlue">PARA BRINDARTE UN SERVICIO MAS PERSONALIZADO EN POCO TIEMPO UNO DE NUESTROS ASESORES ESTARÁ EN CONTACTO CONTIGO </p>
   <br>
   
  <p class="text-thankscompany2 textBlack">Nuestro servicio se especializa en estar de la mano con el cliente,</p>
  <br>
  <br>
  <h3 class="text-subtitulo">YA PUEDES UTILIZAR KAMGUS EMPRESA</h3>
  <!--BOTONES FINALES-->
  <div
  class="row ocultar2 mb-5 btnGraciasCompany | justify-content-center"
  style="padding: 0% 6% 0% 6%; margin: 0px 6% 0% 6%; display: block ruby"
>
  <div class="responsive-thank__btn btnmarginright" style="margin-left: 60px;">
    <button
      class="col-sm-14 | btn btn-block btn-warning btn-rounded | btn-thanks__login"
      routerLink="/login-company"
    >
      Inicia Sesion Aquí
    </button>
  </div>
  <!-- <div class="responsive-thank__btn btnmarginleft">
    <a
      [href]="web"
      class="col-sm-14 | btn btn-facebook--thnksyou btn-lg btn-block"
      style="color: #fff"
    >
      Volver a página web
    </a>
  </div> -->
</div>
<!--BOTONES FINALES movil-->

<div class="container-fluid ocultar">
  <div
    class="row"
  >
    
      <button
        class="col-md-6 mb-3 btn btnoranje"
        routerLink="/login-company"
      >
        Inicia Sesion Aquí
      </button>
    
    
      <!-- <button
        class="col-md-6  mb-3 btn btnblue"
      >
      <a
        href="https://apps.apple.com/us/app/id1495896386"
        style="color: #fff"
      >
        Volver a página web
      </a>
      </button> -->
    
  
</div>
</div>
  
  <!--
    <div class="row">
    <div class="col-md-6 | responsive-thank">
      <button class="btn btn-block btn-warning btn-rounded | btn-thanks__login" routerLink="/login-company">Inicia Sesion Aquí</button>
    </div>
    <div class="col-md-6 | responsive-thank">
      <a [href]="web" class="btn  btn-facebook btn-lg btn-block | btn-facebook--thnksyou" style="color:#fff"> 
        Volver a página web
      </a>
    </div>
  </div>-->
</div>

<!--

  <div class="container-fluid">
    <div class="row | justify-content-center" style="padding: 0% 6% 0% 6%;
    margin: 0% 6% 0% 6%;
    display: block ruby;">
      <div class="responsive-thank__btn">
        <a href="https://app.kamgus.cf/login-company" > <button class="col-sm-14 btnmarginright | btn btn-block btn-warning__morado btn-rounded | btn-thanks__login " >Inicia Sesion Aquí</button></a>
      </div>
        <div class="responsive-thank__btn">
        <a href="http://kamgus.cf/" >
        <button class="col-sm-14 btnmarginleft | btn btn-block btn-facebook--thnksyoucompany  btn-rounded | btn-thanks__loginvolver" style="color:#fff" >Volver a pagina web</button></a>
        <a [href]="web" class=" col-sm-14 | btn  btn-facebook--thnksyoucompany btn-lg btn-block" > 
          Volver a página web
        </a>
      </div>
    </div>
  </div>
-->