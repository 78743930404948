<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Barra de Progreso</h5>
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" [style.width]="progress + '%'"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-6">
        <div class="card">
            <div class="card-body">

                <app-incrementador (changeProgress)="progress = $event" leyenda="Barra de Progreso" [progress]="progress"></app-incrementador>
                <!--<div class="input-group"> 
                    <div class="input-group-btn">
                        <button class="btn btn-primary" type="button" (click)="barra(-5)">
                          <i class="mdi mdi-minus"></i>
                        </button>
                    </div>

                    <input type="text" class="form-control" [(ngModel)]="progress">

                    <div class="input-group-btn">
                        <button class="btn btn-primary" type="button" (click)="barra(5)">
                            <i class="mdi mdi-plus"></i>
                        </button>
                    </div>
                </div>>-->

            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                Texto 2
            </div>
        </div>
    </div>
</div>