var GraficosComponent = /** @class */ (function () {
    function GraficosComponent() {
        this.doughnutChartLabels = [];
        this.doughnutChartData = [];
        this.doughnutChartType = 'doughnut';
        this.leyenda = '';
    }
    GraficosComponent.prototype.ngOnInit = function () {
    };
    return GraficosComponent;
}());
export { GraficosComponent };
