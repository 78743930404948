import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Modules } from '../enums/modules.enum';
import { ApiService } from '../services/api/api.service';
import { SharedService } from "../services/shared/shared.service";

@Injectable()
export class AdminGuard implements CanActivate {

  rol:string;
  user: any;

  constructor(
    private readonly router: Router,
    private _api: ApiService,
    private sharedService: SharedService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const rol = JSON.parse( localStorage.getItem('user') ).rol;
    // this.user = this.sharedService.decodeJwt();
    // this.rol = this.user.rol;

    // console.log(this.sharedService.decodeJwt());

    // this.sharedService.showUser().subscribe(resp => {
    //   this.rol = resp.data.role;
    // });

    this.rol = JSON.parse(localStorage.getItem('role'));

    if ( this.rol === 'Administrador' ) {
        return true;
    } else {
        this.router.navigate(['/dashboard']);
    }
  }
}

