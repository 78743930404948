import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-graficos',
  templateUrl: './graficos.component.html',
  styles: []
})

export class GraficosComponent implements OnInit {


   @Input() doughnutChartLabels:string[] = [];
   @Input() doughnutChartData:number[] = [];
   @Input() doughnutChartType:string = 'doughnut';
   @Input() leyenda:string = '';

  constructor() { 
  }

  ngOnInit() {
  }

}
