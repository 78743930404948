import { FormBuilder, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
var NewPasswordComponent = /** @class */ (function () {
    function NewPasswordComponent(formBuilder, _api, route, router, toastr) {
        this.formBuilder = formBuilder;
        this._api = _api;
        this.route = route;
        this.router = router;
        this.toastr = toastr;
        this.isEqual = true;
        this.isLoading = false;
    }
    NewPasswordComponent.prototype.ngOnInit = function () {
        this.token = this.route.snapshot.params['token'];
        this.loadForm();
    };
    NewPasswordComponent.prototype.loadForm = function () {
        this.formData = this.formBuilder.group({
            password: ["", [Validators.required]],
            retypePassword: ["", [Validators.required]],
        });
    };
    NewPasswordComponent.prototype.send = function () {
        var _this = this;
        this.isLoading = true;
        var params = new HttpParams()
            .set("token", this.token)
            .set("password", this.formData.value.password)
            .set("password_confirmation", this.formData.value.retypePassword);
        this._api.resetPassword(params).subscribe(function (data) {
            if (data.warning) {
                _this.isLoading = false;
                _this.warning = data.warning;
            }
            else {
                _this.isLoading = false;
                _this.warning = '';
                _this.toastr.success(data.msg);
                _this.router.navigate(["/login"]);
            }
        });
    };
    NewPasswordComponent.prototype.onKey = function (event) {
        console.log(event);
        var password = this.formData.value.password;
        var retypePassword = event.target.value;
        if (password == retypePassword) {
            this.isEqual = true;
        }
        else {
            this.isEqual = false;
        }
    };
    return NewPasswordComponent;
}());
export { NewPasswordComponent };
