<div class="row cont-login login-company">
  <div class="col-md-6 registerDriver pd-forms-login text-center no-display-movil" *ngIf="step === 1">
    <img class="img-80" src="../../assets/images/logo-white2.png" />
    <div class="spacer-100"></div>
    <h3 class="color-white-driver">
      <strong>¡CADA VEZ ESTAS MAS CERCA EN FORMAR <br />
        PARTE DEL EQUIPO DE KAMGUS DRIVE!</strong>
    </h3>
    <p class="color-white p-logindriver">
      Regístrate y conoce todas las bondades que tiene para ti <br />
      prestar un servicio de acarreo de calidad.
      <br />
      <br />
      <i>¡Vive la experiencia Kamgus junto a nosotros!</i>
    </p>
  </div>
  <div class="col-md-6 pd-forms-login text-center no-display-movil" *ngIf="step === 2">
    <img class="img-80" src="../../assets/images/logo-white2.png" />
    <div class="spacer-100"></div>
    <h4 class="color-white | titulo-confirmar"><strong>Confirmar</strong></h4>
    <p class="color-white p-login">
      Confirma los datos de tu suscripción y ya podrá empezar a disfrutar de
      todos los beneficios que Kamgus tiene para ti en materia de traslados.
    </p>
  </div>
  <div class="col-md-6 contenedorRegisterDriver cont-white pd-forms-registerdriver">
    <div class="card contenedorFormresponsive">
      <form class="form-horizontal form-material" [formGroup]="formData" *ngIf="formData && !registerOK && step === 1">
        <h3 class="box-title m-t-40 m-b-0 color-blue text-center titulo | titulo-register--driver">
          REGISTRATE COMO CONDUCTOR
        </h3>
        <br />

        <div class="alert alert-danger" *ngIf="errorMessage !== undefined">
          <i class="ti-close"></i> {{ errorMessage.error.msg }}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <!--  <div class="form-group ">
                      <div class="col-xs-12">
                          <select class="form-control" placeholder="Soy" required [(ngModel)]="typeUser" (change)="selectUser( $event.target.value )" [ngModelOptions]="{standalone: true}">
                              <option value="">Soy...</option>
                              <option value="user">Soy usuario</option>
                              <option value="company">Soy Empresa</option>
                          </select>
                          <div *ngIf="typeUser === ''" class="invalid-feedback">
                             Seleccione un tipo de usuario
                          </div>
                      </div>
                  </div> -->

        <div class="form-group" *ngIf="typeUser === 'company'">
          <div class="col-xs-12">
            <label class="color-blue">Nombre empresa</label>
            <input class="form-control input-register" type="text" required="" placeholder="Nombre de la empresa"
              formControlName="company" />
            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
              <div *ngIf="f.company.errors.required">
                * El nombre de la empresa es requerido
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="col-xs-12">
            <label class="color-blue">Nombre completo</label>
            <input class="form-control input-register" type="text" required="" placeholder="Nombre completo"
              formControlName="nombre" />
            <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
              <div *ngIf="f.nombre.errors.required">
                * El nombre es requerido
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="col-xs-12">
            <div class="form-group">
              <label class="color-blue">Teléfono</label>

              <input class="form-control input-register" mask="0000-0000" prefix="+507 " [showMaskTyped]="false"
                type="text" required="" placeholder="Teléfono celular" formControlName="celular" />
              <div *ngIf="submitted && f.celular.errors" class="invalid-feedback">
                <div *ngIf="f.celular.errors.required">
                  * El celular es requerido
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <label class="color-blue">Correo</label>
            <input class="form-control input-register" type="text" required="" placeholder="Email"
              formControlName="email" (input)="inputValidator($event)" />

            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">* El email es requerido</div>
            </div>
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.email">
                * No es un formato de correo válido
              </div>
            </div>
            <div class="invalid-feedback" *ngIf="emailRegistrado">
              * Email o Teléfono Registrado intente con otro
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <label class="color-blue">Confirmar correo</label>
            <input class="form-control input-register" type="text" required="" placeholder="Confirmar correo"
              formControlName="reTypeEmail" (input)="inputValidator($event)" />

            <div *ngIf="submitted && f.reTypeEmail.errors" class="invalid-feedback">
              <div *ngIf="f.reTypeEmail.errors.required">
                * El correo es requerido
              </div>
            </div>
            <div *ngIf="submitted && f.reTypeEmail.errors" class="invalid-feedback">
              <div *ngIf="f.reTypeEmail.errors.reTypeEmail">
                * No es un formato de correo válido
              </div>
            </div>

            <div *ngIf="submitted && f.email.value !== f.reTypeEmail.value" class="invalid-feedback">
              Correos no coinciden
            </div>
          </div>
        </div>
        <!-- <div class="form-group " >
                      <div class="col-xs-12">
                         <label class="color-blue">Contraseña</label>
                          <show-hide-password size="sm" icon="empty" btnStyle="secondary" [btnOutline]="true">
                              <input class="form-control input-register" type="password" required="" placeholder="" formControlName="password">
                          </show-hide-password>
                         
                          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                              <div *ngIf="f.password.errors.required">* La constraseña es requerida</div>
                          </div>
                          <div *ngIf="submitted && f.password.value !== f.retypePassword.value" class="invalid-feedback">
                              Contraseñas no coinciden
                          </div>
                      </div>
                  </div>
                  <div class="form-group " >
                      <div class="col-xs-12">
                          <label class="color-blue">Confirmar Contraseña</label>
                          <show-hide-password size="sm" icon="empty" btnStyle="secondary" [btnOutline]="true">
                              <input class="form-control input-register" type="password" required="" placeholder="" formControlName="retypePassword">
                          </show-hide-password>
                              <div *ngIf="submitted && f.retypePassword.errors" class="invalid-feedback">
                              <div *ngIf="f.retypePassword.errors.required">* La constraseña es requerida</div>
                          </div>
                          <div *ngIf="submitted && f.password.value !== f.retypePassword.value" class="invalid-feedback">
                              Contraseñas no coinciden
                          </div>
                      </div>
                  </div>-->
        <!--
        <div class="form-group">
          <div class="col-xs-12">
            <label class="color-blue">Correo</label>
            <input
              class="form-control input-register"
              type="text"
              required=""
              placeholder="Email"
              formDataEmail="email"
            />

            <div *ngIf="submitted && g.email.errors" class="invalid-feedback">
              <div *ngIf="g.email.errors.required">* El email es requerido</div>
            </div>
            <div *ngIf="submitted && g.email.errors" class="invalid-feedback">
              <div *ngIf="g.email.errors.email">
                * No es un formato de correo válido
              </div>
            </div>
          </div>
        </div> -->

        <div class="form-group">
          <label class="color-blue text-left">Licencia y Cedula o Pasaporte</label>
          <br />
          <!--<a href="https://signup.getmati.com/?merchantToken=65709a96beeb51001c12cf73&flowId=">Open</a>-->
          <div class="col-md-12 marginresponsive">
            <!--aqui input con foto en para añadir fotos class="d-none" hacer input ivisible-->
            <input type="file" (change)="onPhotoSelected($event)" class="d-none" required #fotoinput
              accept=".jpg, .jpeg" />

            <input type="file" (change)="onPhotoSelected1($event)" class="d-none" #fotoinput1 accept=".jpg, .jpeg" />
            <!--*ngIf="photoSelected==!null "-->
            <img [src]="photoSelected || '../../../assets/images/foto.png'" class="img-fluid imgCamara"
              (click)="fotoinput.click()" width="60px" />
            <span class="texto-encima">Licencia</span>
            <img [src]="photoSelected1 || '../../../assets/images/foto.png'" class="img-fluid imgCamara"
              (click)="fotoinput1.click()" width="60px" />
            <span class="texto-encima">Cedula</span>

            <!-- 
             <img
              class="animacion"
              src="../../../assets/images/add.png"
              class="img-fluid"
              (click)="fotoinput1.click()"
            /> 
          -->
          </div>
        </div>
        <div *ngIf="photoSelected == null && fotosObligatorias == true" class="invalid-feedback">
          * Foto de Licencia Obligatoria
        </div>
        <div *ngIf="photoSelected1 == null && fotosObligatorias1 == true" class="invalid-feedback">
          * Foto de Cedula o Pasaporte Obligatoria
        </div>
        <br />

      </form>

      <form class="form-horizontal form-material" [formGroup]="formDataEmail"
        *ngIf="formDataEmail && !registerOK && step === 2">
        <h3 class="box-title m-t-40 m-b-0 color-blue text-center | titulo-register--driverconfirmar">
          CONFIRMAR SUSCRIPCION
        </h3>
        <br />
        <div class="card-pricing--driver">
          <h3 class="box-title color-blue text-center">
            <small class="text-requisitos--driver">Requisitos de aprobación</small>
          </h3>
          <ul class="textoLista">
            <li>Debe ser mayor de edad</li>
            <li>Poseer vehículo en buen estado</li>
            <li>Vehículo con seguro de carga</li>
            <li>Buen historial de transito</li>
            <li>Record policial</li>
          </ul>
        </div>

        <div class="form-group m-t-20">
          <div class="col-xs-12">
            <label class="color-blue">Contraseña</label>
            <show-hide-password class="btn_ojo" size="sm" icon="empty" btnStyle="secondary" [btnOutline]="true">
              <input class="form-control input-register" type="password" required="" placeholder=""
                formControlName="password" />
            </show-hide-password>

            <div *ngIf="submitted && g.password.errors" class="invalid-feedback">
              <div *ngIf="g.password.errors.required">
                * La constraseña es requerida
              </div>
            </div>
            <div *ngIf="submitted && g.password.value !== g.retypePassword.value" class="invalid-feedback">
              Contraseñas no coinciden
            </div>
          </div>
        </div>

        <div class="form-group m-t-20">
          <div class="col-xs-12">
            <label class="color-blue">Confirmar Contraseña</label>
            <show-hide-password class="btn_ojo" size="sm" icon="empty" btnStyle="secondary" [btnOutline]="true">
              <input class="form-control input-register" type="password" required="" placeholder=""
                formControlName="retypePassword" />
            </show-hide-password>
            <div *ngIf="submitted && g.retypePassword.errors" class="invalid-feedback">
              <div *ngIf="g.retypePassword.errors.required">
                * La constraseña es requerida
              </div>
            </div>
            <div *ngIf="submitted && g.password.value !== g.retypePassword.value" class="invalid-feedback">
              Contraseñas no coinciden
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <!--     
                <div class="checkbox checkbox-primary p-t-0">
                <input
                    id="checkbox-signup"
                    type="checkbox"
                    formControlName="terms"
                  />
                  
                  <label for="checkbox-signup" class="acepto-registrer">
                    Acepto
                    <a href="#" class="acepto-registrer"
                      >términos y condiciones</a
                    ></label
                  >
                  <div
                    *ngIf="submitted && g.terms.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="g.terms.errors.required && !g.terms.value">
                      Aceptar términos y condiciones
                    </div>
                  </div>
                
                </div>
              -->
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="">
              <div class="checkbox checkbox-primary p-t-0 terminosP">
                <input id="checkbox-signup" type="checkbox" formControlName="terms" />
                <label for="checkbox-signup">
                  Acepto
                  <a href="https://kamgus.cf/terminos-y-condiciones-conductores/">términos y condiciones</a></label>
              </div>
              <div *ngIf="submitted && g.terms.errors" class="invalid-feedback">
                <div *ngIf="g.terms.errors.required && !g.terms.value">
                  * Debe aceptar términos y condiciones
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div *ngIf="step === 1" class="form-group text-center m-t-20">
        <div class="col-xs-12">
          <button class="btn-info--registerdriver" type="submit" (click)="next(step)">
            <div *ngIf="isLoading" class="lds-dual-ring"></div>
            <span>Siguiente 1 / 3</span>
          </button>
        </div>
        <br />
      </div>
      <!--paso 2-->
      <div *ngIf="step === 2" class="form-group text-center m-t-20">
        <div class="col-xs-6 mb-1">
          <button class="btn-info--registerdriver-outline" type="submit" (click)="(step=step-1)" [disabled]="isLoading">
            <div *ngIf="isLoading" class="lds-dual-ring"></div>
            <span>Atras</span>
          </button>
        </div>
        <div class="col-xs-6">
          <button class="btn-info--registerdriver" type="submit" (click)="next(step)" [disabled]="isLoading">
            <div *ngIf="isLoading" class="lds-dual-ring"></div>
            <span>Siguiente 2 / 3</span>
          </button>
        </div>
        <br />
      </div>
      <!---->
    </div>
  </div>
</div>