import { ApiService } from './../../services/api/api.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpParams } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.css']
})
export class DeleteAccountComponent implements OnInit {
  public formData: FormGroup;
  public errorMessage: any;
  public emailRegistrado = false;
  public submitted = false;
  isLoading:boolean = false;
  typeUser = "user";
  constructor(private formBuilder: FormBuilder,
    private _api: ApiService,
    private router: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loadForm();
  }
  loadForm(){
    this.formData = this.formBuilder.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],     
      //terms: ["", [Validators.required]],
    });
  }
  inputValidator(e: any){

  }
  clickDeleteAccount(){
    if (!confirm("¿Esta segura de eliminar esta cuenta?")) {
        // user clicked OK
        return;
    }
    this.submitted = true;
    //alert(this.formData.value.email+" : "+this.formData.value.password);
    this._api.deleteAccount(this.formData.value).subscribe((data: any) => {
      alert("Su cuenta fue eliminada exitosamente");
    }, (err: any) => {
      debugger;
      alert("Error:  \n"+err.error.msg);
    });
  }
  get f() {
    return this.formData.controls;
  }
}
