import { environment } from '../../../environments/environment';
var ThankyouPageCompanyComponent = /** @class */ (function () {
    function ThankyouPageCompanyComponent() {
    }
    ThankyouPageCompanyComponent.prototype.ngOnInit = function () {
        this.web = environment.web;
        this.google = environment.googleDriver;
        this.ios = environment.iosDriver;
    };
    return ThankyouPageCompanyComponent;
}());
export { ThankyouPageCompanyComponent };
