import { SettingsService } from '../../services/service.index';
var AccountSettingsComponent = /** @class */ (function () {
    function AccountSettingsComponent(_document, _ajustes) {
        this._document = _document;
        this._ajustes = _ajustes;
    }
    AccountSettingsComponent.prototype.ngOnInit = function () {
        this.colocarCheck();
    };
    AccountSettingsComponent.prototype.cambiarColor = function (tema, link) {
        this.aplicarCheck(link);
        this._ajustes.aplicarTema(tema);
    };
    AccountSettingsComponent.prototype.aplicarCheck = function (link) {
        var selectores = document.getElementsByClassName('selector');
        for (var _i = 0, selectores_1 = selectores; _i < selectores_1.length; _i++) {
            var ref = selectores_1[_i];
            ref.classList.remove('working');
        }
        link.classList.add('working');
    };
    AccountSettingsComponent.prototype.colocarCheck = function () {
        var selectores = document.getElementsByClassName('selector');
        var tema = this._ajustes.ajustes.tema;
        for (var _i = 0, selectores_2 = selectores; _i < selectores_2.length; _i++) {
            var ref = selectores_2[_i];
            if (ref.getAttribute('data-theme') === tema) {
                ref.classList.add('working');
                break;
            }
        }
    };
    return AccountSettingsComponent;
}());
export { AccountSettingsComponent };
