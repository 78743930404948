import { HttpClient } from "@angular/common/http";
import * as jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
var apikamgus_base_url = environment.apikamgus_base_url;
var SharedService = /** @class */ (function () {
    function SharedService(toastr, http) {
        this.toastr = toastr;
        this.http = http;
    }
    SharedService.prototype.decodeJwt = function () {
        var token = localStorage.getItem('token');
        var decoded = jwt_decode(token);
        return decoded;
    };
    SharedService.prototype.getRole = function () {
        var role = JSON.parse(localStorage.getItem('role'));
        return role;
    };
    SharedService.prototype.calculatePriceByVehicule = function (totalTime, precio_minuto, precio_ayudante, tiempo) {
        // tslint:disable-next-line: radix
        var timeMaps = Math.round((parseInt(totalTime) / 60) * 2);
        precio_minuto = Math.round(timeMaps * precio_minuto) + parseInt(precio_ayudante) + tiempo * 60 * precio_minuto;
        return precio_minuto;
    };
    Object.defineProperty(SharedService.prototype, "token", {
        get: function () {
            return 'Bearer ' + JSON.parse(localStorage.getItem('token')) || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SharedService.prototype, "headers", {
        get: function () {
            return {
                headers: {
                    'Authorization': this.token
                }
            };
        },
        enumerable: true,
        configurable: true
    });
    SharedService.prototype.showSuccess = function (msg) {
        this.toastr.success(msg, '¡Exitoso!', {
            timeOut: 2000,
        });
    };
    SharedService.prototype.showError = function (msg) {
        this.toastr.error(msg, 'Error', {
            timeOut: 2000,
        });
    };
    SharedService.prototype.showUser = function () {
        var url = apikamgus_base_url + "/user";
        return this.http.get(url, this.headers).pipe(tap(function (resp) {
            return resp;
        }));
    };
    return SharedService;
}());
export { SharedService };
