import { HttpParams } from "@angular/common/http";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../services/api/api.service";
import { LoginService } from "../services/login/login.service";
import { StorageService } from "../services/storage/storage.service";
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(formBuilder, loginService, storageService, route, _api, router) {
        this.formBuilder = formBuilder;
        this.loginService = loginService;
        this.storageService = storageService;
        this.route = route;
        this._api = _api;
        this.router = router;
        //detectar si hace submit o no
        this.submitted = false;
        this.failedLogin = false;
        this.typeUser = "user";
        this.registerOK = false;
        this.licenseId = "1";
        this.step = 1;
        this.emailRegistrado = false;
        this.celularRegistrado = false;
        this.isLoading = false;
    }
    RegisterComponent.prototype.ngOnInit = function () {
        init_plugins();
        this.loadForm();
        if (localStorage.getItem("user") || localStorage.getItem("user") !== null) {
            this.route.navigate(["/dashboard"]);
        }
        this.validateLicence();
    };
    RegisterComponent.prototype.validateLicence = function () {
        var _this = this;
        if (this.router.queryParams["_value"] &&
            this.router.queryParams["_value"].licenseKey) {
            this.licenseId = this.router.queryParams["_value"].licenseKey;
        }
        this._api.licences().subscribe(function (lic) {
            lic["licences"].forEach(function (element) {
                if (element.id === _this.licenseId) {
                    _this.licenseId = element.id;
                }
            });
        });
        console.log(this.licenseId);
    };
    RegisterComponent.prototype.loadForm = function () {
        this.formData = this.formBuilder.group({
            nombre: ["", [Validators.required]],
            celular: ["", [Validators.required]],
            documento_entidad: [0, [Validators.required]],
            password: ["", [Validators.required]],
            retypePassword: ["", [Validators.required]],
            terms: ["", [Validators.required]],
            company: ["", []],
        });
        this.formDataEmail = this.formBuilder.group({
            email: ["", [Validators.required, Validators.email]],
            reTypeEmail: ["", [Validators.required, Validators.email]],
        });
    };
    // send() {
    //   this.submitted = true;
    //   if (!this.formData.valid) {
    //     return;
    //   }
    //   const request = this.updateModel(this.formData.value);
    //   console.log(request);
    //   this.loginService.register(request).subscribe(
    //     (response) => {
    //       console.log("response", response);
    //       this.createLicense(response["id_user"]);
    //     },
    //     (err) => {
    //       console.log("err", err);
    //       this.errorMessage = err;
    //     }
    //   );
    //   console.log(this.formData.value);
    // }
    RegisterComponent.prototype.updateModel = function (form) {
        var params = new HttpParams()
            .set("email", this.formDataEmail.value.email)
            .set("nombre", this.typeUser === "company" ? form.company : form.nombre)
            .set("celular", form.celular)
            .set("documento_entidad", form.documento_entidad)
            .set("password", form.password)
            .set("rol", this.typeUser === "company" ? "5" : "2");
        return params;
    };
    Object.defineProperty(RegisterComponent.prototype, "f", {
        get: function () {
            return this.formData.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterComponent.prototype, "g", {
        get: function () {
            return this.formDataEmail.controls;
        },
        enumerable: true,
        configurable: true
    });
    RegisterComponent.prototype.selectUser = function ($event) {
        this.typeUser = $event;
        if (this.typeUser === "company") {
            this.formData.get("company").setValidators(Validators.required);
            this.formData.get("company").updateValueAndValidity();
        }
        else {
            this.formData.get("company").clearValidators();
            this.formData.get("company").updateValueAndValidity();
        }
    };
    // createLicense(id_user: string) {
    //   const params = new HttpParams()
    //     .set("id_user", id_user)
    //     .set("id_licence", this.licenseId);
    //   this._api.activateFreeLicence(params).subscribe(
    //     (licence) => {
    //       this.createCustomer(id_user);
    //       this.registerOK = true;
    //       this.route.navigate(["/thankyou-page-company"]);
    //     },
    //     (err) => {
    //       this.errorMessage = err;
    //     }
    //   );
    // }
    // createCustomer(id_user: string) {
    //   const params = new HttpParams()
    //     .set("firstName", this.formData.value.nombre)
    //     .set("lastName", null)
    //     .set("phone", this.formData.value.celular)
    //     .set("email", this.formData.value.email)
    //     .set("idusuario", id_user)
    //     .set("id", this.formData.value.documento_entidad);
    //   this._api.genericPost("/createCustomer", params).subscribe(
    //     (respo) => {
    //       console.log("Customer creado...", respo);
    //     },
    //     (err) => {
    //       console.log("error create customerId", err);
    //     }
    //   );
    // }
    /*API NUEVO*/
    // crearUsuarioCompany() {
    //   this.submitted = true;
    //   // const password = this.formData.value.password;
    //   // let passwordHash = bcrypt.hashSync(password, 10);
    //   this.loginService
    //     .crearUsuario({
    //       // RegistroForm: {
    //       //   nombre: "empresaxyz",
    //       //   apellidos: "betin",
    //       //   celular: "12345678",
    //       //   password: "123456789",
    //       //   email: "empresaxyz@gmail.com",
    //       //   pais_id: "176",
    //       //   documento_entidad: "",
    //       //   url_foto: "",
    //       //   licencia_foto: "",
    //       //   tipo_documento_id: "1",
    //       //   rol: this.typeUser == "5",
    //       // },
    //       company: {
    //         nombre_empresa: this.formData.value.company,
    //         nombre_contacto: this.formData.value.nombre,
    //         telefono: this.formData.value.celular,
    //       },
    //       user: {
    //         email: this.formDataEmail.value.email,
    //         password: this.formData.value.password,
    //       },
    //     })
    //     .subscribe((respuesta) => {
    //       console.log("Usuario Creado");
    //       this.registerOK = true;
    //       this.route.navigate(["/thankyou-page-company"]);
    //     });
    // }
    RegisterComponent.prototype.next = function (step) {
        var _this = this;
        this.isLoading = true;
        var params = new HttpParams()
            .set("email", 'prebakamgus@gmail.com')
            .set("telefono", this.formData.value.celular);
        console.log(this.formData);
        this.submitted = true;
        if (step === 1) {
            if (!this.formData.valid) {
                this.isLoading = false;
                return;
            }
            /**** Agregado */
            this._api
                .validateUser(params)
                .subscribe(function (data) {
                _this.step += 1;
                _this.isLoading = false;
            }, function (err) {
                _this.celularRegistrado = true;
                _this.isLoading = false;
                console.log(err);
            });
            // this.step += 1;
            // this.isLoading = false;
        }
        /*if( step === 2 ) {
          this.step += 1;
        }*/
        //if( step === 3 ) {
        if (step === 2) {
            this.isLoading = true;
            var params_email = new HttpParams()
                .set("email", this.formDataEmail.value.email)
                .set("telefono", this.formData.value.celular);
            if (!this.formDataEmail.valid) {
                this.isLoading = false;
                return;
            }
            else if (this.g.email.value !== this.g.reTypeEmail.value) {
                this.isLoading = false;
                return;
            }
            else {
                //this.send();
                // this.crearUsuarioCompany();
                this._api
                    .validateUser(params_email)
                    .subscribe(function (data) {
                    _this.step += 1;
                    _this.createUserCompany();
                    _this.isLoading = false;
                }, function (err) {
                    _this.emailRegistrado = true;
                    _this.isLoading = false;
                    console.log(err);
                });
                // this.isLoading = false;
                // this.createUserCompany();
            }
        }
    };
    //nueva api (apikamgus)
    RegisterComponent.prototype.createUserCompany = function () {
        var _this = this;
        this.isLoading = true;
        this.submitted = true;
        var params = new HttpParams()
            .set("nombre_empresa", this.formData.value.company)
            .set("nombre_contacto", this.formData.value.nombre)
            .set("telefono", this.formData.value.celular)
            .set("email", this.formDataEmail.value.email)
            .set("password", this.formData.value.password)
            .set("password_confirmation", this.formData.value.password);
        this.loginService.registerCompanyUser(params).subscribe(function (data) {
            // console.log("Usuario Creado");
            _this.isLoading = false;
            _this.registerOK = true;
            _this.route.navigate(["/thankyou-page-company"]);
        }, function (err) {
            console.log(err);
        });
    };
    return RegisterComponent;
}());
export { RegisterComponent };
