import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
//import { HashLocationStrategy, LocationStrategy } from '@angular/common';

//COMPONENTS
import { AppComponent } from "./app.component";
// import { LoginComponent } from "./login/login.component";
import { LoginComponent } from "./login/login/login.component";

//MODULES
import { PagesModule } from "./pages/pages.module";

//ROUTES
import { APP_ROUTES } from "./app.routes";
import { PagesComponent } from "./pages/pages.component";
import { RegisterComponent } from "./login/register.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ServiceModule } from "./services/service.module";
import { AuthGuard } from "./guards/auth.guard";
import { LoginService } from "./services/login/login.service";

import { HttpClientModule } from "@angular/common/http";
import { ApiService } from "./services/api/api.service";
import { StorageService } from "./services/storage/storage.service";
import { LicenceGuard } from "./guards/licence.guard";
import { AdminGuard } from "./guards/admin.guard";
import { ToastrModule } from "ngx-toastr";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginCompanyComponent } from "./login/login-company/login-company.component";
import { RegisterUserComponent } from "./login/register/register-user/register-user.component";
import { ShowHidePasswordModule } from "ngx-show-hide-password";
import { ThankyouPageComponent } from "./login/thankyou-page/thankyou-page.component";
import { RegisterDriverComponent } from "./login/register-driver/register-driver.component";
import { ThankyouPageDriverComponent } from "./login/thankyou-page-driver/thankyou-page-driver.component";
import { ThankyouPageCompanyComponent } from "./login/thankyou-page-company/thankyou-page-company.component";
import { RecuperarClaveComponent } from "./login/recuperar-clave/recuperar-clave.component";

import {FormSupportComponent} from "./pages/support/support/form-support/form-support.component";
import { NewPasswordComponent } from './login/new-password/new-password.component';
import { IntroAppsComponent } from './login/intro-apps/intro-apps.component';
import { DeleteAccountComponent } from "./login/delete-account/delete-account.component";
import { StripeService } from "./services/Stripe/stripe.service";
import { UsuarioService } from "./services/Users/usuario.service";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    LoginCompanyComponent,
    RegisterUserComponent,
    ThankyouPageComponent,
    RegisterDriverComponent,
    ThankyouPageDriverComponent,
    ThankyouPageCompanyComponent,
    RecuperarClaveComponent,
    NewPasswordComponent,
    FormSupportComponent,
    IntroAppsComponent,
    DeleteAccountComponent,
  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    PagesModule,
    FormsModule,
    ServiceModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    ShowHidePasswordModule.forRoot(),
  ],
  providers: [
    //{ provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuard,
    LoginService,
    StorageService,
    LicenceGuard,
    AdminGuard,
    StripeService,
    UsuarioService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
