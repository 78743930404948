import { Router } from '@angular/router';
import { ApiService } from '../services/api/api.service';
import { SharedService } from "../services/shared/shared.service";
var AdminGuard = /** @class */ (function () {
    function AdminGuard(router, _api, sharedService) {
        this.router = router;
        this._api = _api;
        this.sharedService = sharedService;
    }
    AdminGuard.prototype.canActivate = function (route, state) {
        // const rol = JSON.parse( localStorage.getItem('user') ).rol;
        // this.user = this.sharedService.decodeJwt();
        // this.rol = this.user.rol;
        // console.log(this.sharedService.decodeJwt());
        // this.sharedService.showUser().subscribe(resp => {
        //   this.rol = resp.data.role;
        // });
        this.rol = JSON.parse(localStorage.getItem('role'));
        if (this.rol === 'Administrador') {
            return true;
        }
        else {
            this.router.navigate(['/dashboard']);
        }
    };
    return AdminGuard;
}());
export { AdminGuard };
