/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./buy-licence.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../services/api/api.service";
import * as i4 from "@angular/common/http";
import * as i5 from "./buy-licence.component";
import * as i6 from "../../services/shared/shared.service";
var styles_BuyLicenceComponent = [i0.styles];
var RenderType_BuyLicenceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BuyLicenceComponent, data: {} });
export { RenderType_BuyLicenceComponent as RenderType_BuyLicenceComponent };
function View_BuyLicenceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "row text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "col-md-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "h4", [["class", "actual-licence"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Su licencia actual es: "])), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["Desde: ", ""])), i1.ɵppd(11, 2), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actual.name; _ck(_v, 7, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent, 0), _co.actual.createdAtUser, "short")); _ck(_v, 10, 0, currVal_1); }); }
function View_BuyLicenceComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "mdi mdi-check"], ["style", "color: green;"]], null, null, null, null, null))], null, null); }
function View_BuyLicenceComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "mdi mdi-close"], ["style", "color: red;"]], null, null, null, null, null))], null, null); }
function View_BuyLicenceComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "card-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BuyLicenceComponent_5)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BuyLicenceComponent_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(7, null, ["\n            \n            ", "\n          "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.active; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_v.context.$implicit.active; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.item; _ck(_v, 7, 0, currVal_2); }); }
function View_BuyLicenceComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["$"]))], null, null); }
function View_BuyLicenceComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "div", [["class", "col-md-4"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 24, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Card image cap"], ["class", "card-img-top"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 19, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h4", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "ul", [["class", "non-style"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BuyLicenceComponent_4)), i1.ɵdid(14, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "h4", [["class", "price"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BuyLicenceComponent_7)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(21, null, [" ", ""])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "a", [["class", "btn btn-primary btn-block"], ["style", "color: #fff;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activateLicence(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Adquirir"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.setAttributes; _ck(_v, 14, 0, currVal_3); var currVal_4 = (_v.context.$implicit.price !== "FREE"); _ck(_v, 20, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.actual && _co.actual.id_licence) ? (_co.actual.id_licence === _v.context.$implicit.id_licence) : ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.img, ""); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.title; _ck(_v, 9, 0, currVal_2); var currVal_5 = _v.context.$implicit.price; _ck(_v, 21, 0, currVal_5); }); }
function View_BuyLicenceComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BuyLicenceComponent_3)), i1.ɵdid(3, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.licences; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_BuyLicenceComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BuyLicenceComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BuyLicenceComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.actual !== undefined); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.licences; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_BuyLicenceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-buy-licence", [], null, null, null, View_BuyLicenceComponent_0, RenderType_BuyLicenceComponent)), i1.ɵprd(512, null, i3.ApiService, i3.ApiService, [i4.HttpClient]), i1.ɵdid(2, 114688, null, 0, i5.BuyLicenceComponent, [i3.ApiService, i6.SharedService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var BuyLicenceComponentNgFactory = i1.ɵccf("app-buy-licence", i5.BuyLicenceComponent, View_BuyLicenceComponent_Host_0, {}, {}, []);
export { BuyLicenceComponentNgFactory as BuyLicenceComponentNgFactory };
