import { Router } from '@angular/router';
import { ApiService } from '../services/api/api.service';
import { SharedService } from '../services/shared/shared.service';
import { map } from 'rxjs/operators';
var LicenceGuard = /** @class */ (function () {
    // user;
    function LicenceGuard(router, _api, sharedService) {
        this.router = router;
        this._api = _api;
        this.sharedService = sharedService;
        this.modules = [];
        this.active = false;
        this.finishes = false;
    }
    LicenceGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        // console.log( route );
        // this.user = this.sharedService.decodeJwt();
        // const licencia = localStorage.getItem('licence');
        var role = JSON.parse(localStorage.getItem("role"));
        if (role === 'Administrador') {
            return true;
        }
        // const params = new HttpParams()
        // .set('licence', JSON.parse(licencia).licence );
        // this._api.licencesModules( params ).subscribe( ok => {
        //   this.modules  = ok['modules'];
        //   if (this.modules !== undefined) {
        //     let cont = 0;
        //     this.modules.forEach(element => {
        //         if ( route.data && route.data.module === element.module ) {
        //           this.active = true;
        //         }
        //         cont++;
        //         if ( cont === this.modules.length) {
        //           this.finishes = true;
        //           if ( !this.active ) {
        //             //this.router.navigate(['/buy-licence']);
        //             console.log("licencia")
        //           }
        //         }
        //     });
        //   }
        // }, err => {
        //   //this.router.navigate(['/buy-licence']);
        //   console.log("licencia error")
        // });
        // this._api.licenceModules().subscribe(data => {
        //   this.modules = data.modules;
        //   if(data.modules.length <= 0){
        //     console.log("No tienes licencia");
        //   }
        // });
        // for (let index = 0; index < this.modules.length; index++) {
        //   // var active = false;
        //   var element = this.modules[index].module;
        //   console.log(element);
        //   if ( route.data && route.data.module === element ) {
        //     this.active = true;
        //   }
        // }
        // if( this.active ){
        //     return true;
        // }
        return this._api.licenceModules().pipe(map(function (data) {
            _this.modules = data.modules;
            if (data.modules.length <= 0) {
                console.log("No tienes licencia");
                return false;
            }
            for (var index = 0; index < _this.modules.length; index++) {
                // var active = false;
                var element = _this.modules[index].module;
                // console.log(element);
                if (route.data && route.data.module === element) {
                    return true;
                }
            }
        }));
    };
    return LicenceGuard;
}());
export { LicenceGuard };
