<div class="row cont-login login-company">
  <div
    class="col-md-6 pd-forms-login text-center no-display-movil"
    *ngIf="step === 1"
  >
    <img class="img-80" src="../../assets/images/logo-white2.png" />
    <div class="spacer-100"></div>
    <h4 class="text-info--registrer"><strong>INFO EMPRESA</strong></h4>
    <p class="color-white p-login">
      Registra los datos de la empresa en nuestra red para servicios de
      acarreos, mudanza y logística en Panamá. Empieza a disfrutar del mejor
      servicio con Kamgus
    </p>
  </div>

  <div
    class="col-md-6 pd-forms-login text-center no-display-movil"
    *ngIf="step === 2"
  >
    <img class="img-80" src="../../assets/images/logo-white2.png" />
    <div class="spacer-100"></div>
    <h4 class="color-white | titulo-confirmar">
      <strong>Método de pago</strong>
    </h4>
    <p class="color-white p-login">
      Llena todos los datos solicitados de tu método de pago, selecciona tu plan
      ideal y el periodo de funcionamiento para los servicios de Kamgus.
    </p>
  </div>

  <div
    class="col-md-6 pd-forms-login text-center no-display-movil"
    *ngIf="step === 3"
  >
    <img class="img-80" src="../../assets/images/logo-white2.png" />
    <div class="spacer-100"></div>
    <h4 class="color-white | titulo-confirmar"><strong>Confirmar</strong></h4>
    <p class="color-white p-login">
      Confirma los datos de tu suscripción y ya podrá empezar a disfrutar de
      todos los beneficios que Kamgus tiene para ti en materia de traslados.
    </p>
  </div>

  <div class="col-md-6 cont-white pd-forms-register contenedorRegisterComponent">
    <div class="card">
      <form
        class="form-horizontal form-material"
        [formGroup]="formData"
        *ngIf="formData && !registerOK && step === 1"
      >
        <h3
          class="box-title m-t-40 m-b-0 color-blue text-center tituloEmpresa | titulo-register"
        >
        REGISTRATE COMO EMPRESA
        </h3>
        <br />

        <div class="alert alert-danger" *ngIf="errorMessage !== undefined">
          <i class="ti-close"></i>
          {{ errorMessage.error.msg }}
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <!--  <div class="form-group ">
                    <div class="col-xs-12">
                        <select class="form-control" placeholder="Soy" required [(ngModel)]="typeUser" (change)="selectUser( $event.target.value )" [ngModelOptions]="{standalone: true}">
                            <option value="">Soy...</option>
                            <option value="user">Soy usuario</option>
                            <option value="company">Soy Empresa</option>
                        </select>
                        <div *ngIf="typeUser === ''" class="invalid-feedback">
                           Seleccione un tipo de usuario
                        </div>
                    </div>
                </div> -->

        <div class="form-group">
          <div class="col-xs-12">
            <label class="color-blue">Nombre empresa</label>
            <input
              class="form-control input-register"
              type="text"
              required=""
              placeholder="Nombre de la empresa"
              formControlName="company"
            />
            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
              <div *ngIf="f.company.errors.required">
                * El nombre de la empresa es requerido
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="col-xs-12">
            <label class="color-blue">Nombre de contacto</label>
            <input
              class="form-control input-register"
              type="text"
              required=""
              placeholder="Nombre de contacto"
              formControlName="nombre"
            />
            <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
              <div *ngIf="f.nombre.errors.required">
                * El nombre es requerido
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="col-xs-12">
            <label class="color-blue">Teléfono</label>
            <input
              class="form-control input-register"
              mask="0000-0000"
              prefix="+507 "
              [showMaskTyped]="false"
              type="text"
              required=""
              placeholder="Teléfono celular"
              formControlName="celular"
            />
            <div *ngIf="submitted && f.celular.errors" class="invalid-feedback">
              <div *ngIf="f.celular.errors.required">
                * El celular es requerido
              </div>
            </div>
            <div class="invalid-feedback" *ngIf="celularRegistrado">
              * Telefono Registrado intente con otro
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="col-xs-12">
            <label class="color-blue">Contraseña</label>
            <show-hide-password
              class="btn_ojo"
              size="sm"
              icon="empty"
              btnStyle="secondary"
              [btnOutline]="true"
            >
              <input
                class="form-control input-register"
                type="password"
                required=""
                placeholder=""
                formControlName="password"
              />
            </show-hide-password>

            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.password.errors.required">
                * La constraseña es requerida
              </div>
            </div>
            <div
              *ngIf="submitted && f.password.value !== f.retypePassword.value"
              class="invalid-feedback"
            >
              Contraseñas no coinciden
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <label class="color-blue">Confirmar Contraseña</label>
            <show-hide-password
              class="btn_ojo"
              size="sm"
              icon="empty"
              btnStyle="secondary"
              [btnOutline]="true"
            >
              <input
                class="form-control input-register"
                type="password"
                required=""
                placeholder=""
                formControlName="retypePassword"
              />
            </show-hide-password>
            <div
              *ngIf="submitted && f.retypePassword.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.retypePassword.errors.required">
                * La constraseña es requerida
              </div>
            </div>
            <div
              *ngIf="submitted && f.password.value !== f.retypePassword.value"
              class="invalid-feedback"
            >
              Contraseñas no coinciden
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-12">
            <div class="checkbox checkbox-primary p-t-0 terminosP">
              <input
                id="checkbox-signup"
                type="checkbox"
                formControlName="terms"
              />
              <label for="checkbox-signup" class="acepto-registrer label-acept">
                Acepto
                <a href="#" class="acepto-registrer textoAcepto"
                  >términos y condiciones</a
                ></label
              >
              <div *ngIf="submitted && f.terms.errors" class="invalid-feedback">
                <div *ngIf="f.terms.errors.required && !f.terms.value">
                  Aceptar términos y condiciones
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <!--
            <div class="row" *ngIf="step === 2">
                <div class="col-md-12">
                    <h3 class="color-blue text-center | titulo-metodos ">MÉTODO DE PAGO</h3>
                    <div class="box-payment">
                        <form class="row form-horizontal form-material" >
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="" class="label-register">Titular tarjeta </label>
                                <input autocomplete="none" type="text" class="form-control input-register" placeholder="Nombre de la tarjeta" >
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="" class="label-register">Número Tarjeta</label>
                                <input autocomplete="none" type="text" class="form-control input-register" placeholder="Número de la tarjeta"  mask="0000 0000 0000 0000">
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="" class="label-register"> AÑO</label>
                                <input autocomplete="none" type="text" class="form-control input-register" placeholder="Año"  mask="00">
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="" class="label-register"> MES</label>
                                <input autocomplete="none" type="text" class="form-control input-register" placeholder="Mes"  mask="00">
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="" class="label-register"> CVC</label>
                                <input autocomplete="none" type="text" class="form-control input-register" placeholder="CVV"  maxlength="4">
                              </div>
                            </div>
                          
                        </form>
                    </div>
                    <br><br>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <label class="color-blue_labelpago">Elegir plan</label>
                            <select class="form-control input-register" placeholder="Soy" required >
                                <option value="">...</option>
                                <option value="">....</option>
                            </select>
                        </div>
                        <div class="col-xs-12">
                            <label class="color-blue_labelpago">Periodo del plan</label>
                            <input autocomplete="none" type="text" class="form-control input-register" placeholder="entre + meses descuento %" >

                        </div>
                        <br>
                        <div class="col-xs-12">
                            <input id="checkbox-signup" type="checkbox" >
                            <label class="color-blue"> Guardar datos para futuras compras</label>
                        </div>
                    </div> 

                </div>
            </div>

          <form class="form-horizontal form-material" [formGroup]="formDataEmail" *ngIf="formDataEmail && !registerOK && step === 3">

        -->
      <form
        class="form-horizontal form-material"
        [formGroup]="formDataEmail"
        *ngIf="formDataEmail && !registerOK && step === 2"
      >
        <h3
          class="box-title m-t-40 m-b-0 color-blue text-center | color-blue_confirmar"
        >
          CONFIRMA SUSCRIPCIÓN
        </h3>
        <br />
        <!--Card-->
        <div class="card-pricing--driver">
          <h3 class="box-title color-blue text-center">
            <small class="text-requisitos--driver"
              >Datos de suscripcion</small
            >
          </h3>
          <ul class="textoLista">
            <li>Servicios Multipuntos</li>
            <li>Cotizar por Artículos y Vehículos</li>
            <li>Billetera Virtual</li>
            <li>Historial de Servicios</li>
            <li>1 Usuario</li>
          </ul>
        </div>
      <!--Card-->    
        <div class="form-group m-t-20">
          <div class="col-xs-12">
            <label class="color-blue">Correo</label>
            <input
              class="form-control input-register"
              type="text"
              required=""
              placeholder="Email"
              formControlName="email"
            />

            <div *ngIf="submitted && g.email.errors" class="invalid-feedback">
              <div *ngIf="g.email.errors.required">* El email es requerido</div>
            </div>
            <div *ngIf="submitted && g.email.errors" class="invalid-feedback">
              <div *ngIf="g.email.errors.email">
                * No es un formato de correo válido
              </div>
            </div>
          </div>
        </div>

        <div class="form-group m-t-20">
          <div class="col-xs-12">
            <label class="color-blue">Confirmar correo</label>
            <input
              class="form-control input-register"
              type="text"
              required=""
              placeholder="Confirmar correo"
              formControlName="reTypeEmail"
            />

            <div
              *ngIf="submitted && g.reTypeEmail.errors"
              class="invalid-feedback"
            >
              <div *ngIf="g.reTypeEmail.errors.required">
                * El correo es requerido
              </div>
            </div>
            <div
              *ngIf="submitted && g.reTypeEmail.errors"
              class="invalid-feedback"
            >
              <div *ngIf="g.reTypeEmail.errors.reTypeEmail">
                * No es un formato de correo válido
              </div>
            </div>

            <div
              *ngIf="submitted && g.email.value !== g.reTypeEmail.value"
              class="invalid-feedback"
            >
              Correos no coinciden
            </div>

            <div class="invalid-feedback" *ngIf="emailRegistrado">
              * Email Registrado intente con otro
            </div>
          </div>
        </div>
       <!-- <div class="checkbox checkbox-primary p-t-0">
          <input id="checkbox-signup" type="checkbox" formControlName="terms" />
          <label for="checkbox-signup" class="acepto-registrer">
            Acepto
            <a href="#" class="acepto-registrer"
              >términos y condiciones</a
            ></label
          >
          <div *ngIf="submitted && f.terms.errors" class="invalid-feedback">
            <div *ngIf="f.terms.errors.required && !f.terms.value">
              Aceptar términos y condiciones
            </div>
          </div>
        </div> -->
      </form>

      <div class="form-group text-center m-t-20">
        <div class="col-xs-12">
          <button
            class="btn btn-info--register btn-lg btn-block text-uppercase waves-effect waves-light"
            type="submit"
            (click)="next(step)"
            [disabled]="isLoading"
          >
            Siguiente {{ step }} / 2
            <div *ngIf="isLoading" class="lds-dual-ring"></div>
          </button>
          <!--<button class="btn btn-info--register btn-lg btn-block text-uppercase waves-effect waves-light" type="submit" (click)="next( step )">Siguiente {{step}} / 3</button>-->
        </div>
        <br />
      </div>
      <!--   <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">
                        <p>Ya tengo cuenta <a href="" routerLink="/login" class="text-info m-l-5"><b>Ingresar</b></a></p>
                    </div>
                </div> -->
    </div>
  </div>
</div>
