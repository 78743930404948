import { HttpParams } from "@angular/common/http";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
//import * as bcrypt from "bcryptjs";
import { ApiService } from "../../../services/api/api.service";
import { LoginService } from "../../../services/login/login.service";
//import { UsuarioService } from '../../../services/Users/usuario.service';
import { StorageService } from "../../../services/storage/storage.service";
var RegisterUserComponent = /** @class */ (function () {
    function RegisterUserComponent(formBuilder, loginService, 
        //private usuarioService: UsuarioService,
        storageService, route, _api, router) {
        this.formBuilder = formBuilder;
        this.loginService = loginService;
        this.storageService = storageService;
        this.route = route;
        this._api = _api;
        this.router = router;
        this.submitted = false;
        this.failedLogin = false;
        this.typeUser = "user";
        this.registerOK = false;
        this.licenseId = "1";
        this.step = 1;
        this.correos = [];
        this.emailRegistrado = false;
        this.telefonoRegistrado = false;
        this.isLoading = false;
        this.emailRegistradoUser = [];
        this.paises = [];
    }
    RegisterUserComponent.prototype.ngOnInit = function () {
        init_plugins();
        this.loadForm();
        if (localStorage.getItem("token") || localStorage.getItem("token") !== null) {
            this.route.navigate(["/dashboard"]);
        }
        this.validateLicence();
        this.getPaises();
    };
    RegisterUserComponent.prototype.validateLicence = function () {
        var _this = this;
        if (this.router.queryParams["_value"] &&
            this.router.queryParams["_value"].licenseKey) {
            this.licenseId = this.router.queryParams["_value"].licenseKey;
        }
        this._api.licences().subscribe(function (lic) {
            lic["licences"].forEach(function (element) {
                if (element.id === _this.licenseId) {
                    _this.licenseId = element.id;
                }
            });
        });
        console.log(this.licenseId);
    };
    /** Obtener paises para código telélefono */
    RegisterUserComponent.prototype.getPaises = function () {
        var _this = this;
        //this.loginService
        //  .paises(environment.apikamgus_base_url+"/countries/flags")
        this._api.genericGet("/countries/flags", "TOV2")
            .subscribe(function (respuesta) {
            console.log("Listado Paises");
            console.log(respuesta);
            _this.paises = respuesta;
        }, function (err) {
            console.log(err);
        });
    };
    RegisterUserComponent.prototype.loadForm = function () {
        this.formData = this.formBuilder.group({
            nombre: ["", [Validators.required]],
            apellido: ["", [Validators.required]],
            celular: ["", [Validators.required]],
            documento_entidad: [0, [Validators.required]],
            terms: ["", [Validators.required]],
            company: ["", []],
            email: ["", [Validators.required, Validators.email]],
            reTypeEmail: ["", [Validators.required, Validators.email]],
        });
        this.formDataEmail = this.formBuilder.group({
            password: ["", [Validators.required]],
            retypePassword: ["", [Validators.required]],
        });
    };
    // send() {
    //   this.submitted = true;
    //   if (!this.formData.valid) {
    //     return;
    //   }
    //   const request = this.updateModel(this.formData.value);
    //   console.log(request);
    //   this.loginService.register(request).subscribe(
    //     (response) => {
    //       console.log("response", response);
    //       this.createLicense(response["id_user"]);
    //     },
    //     (err) => {
    //       console.log("err", err);
    //       this.errorMessage = err;
    //     }
    //   );
    //   console.log(this.formData.value);
    // }
    RegisterUserComponent.prototype.updateModel = function (form) {
        var params = new HttpParams()
            .set("email", this.formDataEmail.value.email)
            .set("nombre", this.typeUser === "company" ? form.company : form.nombre)
            .set("celular", form.celular)
            .set("documento_entidad", form.documento_entidad)
            .set("password", form.password)
            .set("rol", this.typeUser === "company" ? "5" : "2");
        return params;
    };
    Object.defineProperty(RegisterUserComponent.prototype, "f", {
        get: function () {
            return this.formData.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterUserComponent.prototype, "g", {
        get: function () {
            return this.formDataEmail.controls;
        },
        enumerable: true,
        configurable: true
    });
    RegisterUserComponent.prototype.selectUser = function ($event) {
        this.typeUser = $event;
        if (this.typeUser === "company") {
            this.formData.get("company").setValidators(Validators.required);
            this.formData.get("company").updateValueAndValidity();
        }
        else {
            this.formData.get("company").clearValidators();
            this.formData.get("company").updateValueAndValidity();
        }
    };
    // createLicense(id_user: string) {
    //   const params = new HttpParams()
    //     .set("id_user", id_user)
    //     .set("id_licence", this.licenseId);
    //   this._api.activateFreeLicence(params).subscribe(
    //     (licence) => {
    //       this.createCustomer(id_user);
    //       this.registerOK = true;
    //       this.route.navigate(["/thankyou-page-user"]);
    //     },
    //     (err) => {
    //       this.errorMessage = err;
    //     }
    //   );
    // }
    RegisterUserComponent.prototype.createCustomer = function () {
        var _this = this;
        this.isLoading = true;
        var password = this.formDataEmail.value.password;
        var params = new HttpParams()
            .set("nombres", this.formData.value.nombre)
            .set("apellidos", this.formData.value.apellido)
            .set("telefono", this.formData.value.celular)
            .set("email", this.formData.value.email)
            .set("password", password)
            .set("password_confirmation", password);
        this._api.registerCustomer(params).subscribe(function (data) {
            // console.log(data);
            _this.isLoading = false;
            _this.registerOK = true;
            _this.route.navigate(["/thankyou-page-user"]);
        }, function (err) {
            console.log(err);
        });
    };
    /*API NUEVO*/
    // crearUsuario() {
    //   this.submitted = true;
    //   //const password = this.formDataEmail.value.password;
    //   //let passwordHash = bcrypt.hashSync(password, 10);
    //   this.loginService
    //     .crearUsuario({
    //       RegistroForm: {
    //         nombre: this.formData.value.nombre,
    //         apellidos: this.formData.value.apellido,
    //         celular: this.formData.value.celular,
    //         password: this.formDataEmail.value.password,
    //         email: this.formData.value.email,
    //         pais_id: "176",
    //         documento_entidad: "",
    //         url_foto: "",
    //         licencia_foto: "",
    //         tipo_documento_id: "1",
    //         rol: this.typeUser === "company" ? "5" : "2",
    //       },
    //     })
    //     .subscribe(
    //       (respuesta) => {
    //         console.log("Usuario Creado");
    //         console.log(respuesta);
    //         this.registerOK = true;
    //         this.route.navigate(["/thankyou-page-user"]);
    //       },
    //       (err) => {
    //         console.log(err);
    //         this.emailRegistrado = true;
    //       }
    //     );
    //   /*    this.loginService
    //     .crearUsuario("https://apiv2.kamgus.com/api/v2/registration/customers", {
    //       customer: {
    //         nombres: this.formData.value.nombre,
    //         apellidos: this.formData.value.apellido,
    //         telefono: this.formData.value.celular,
    //       },
    //       user: {
    //         email: this.formDataEmail.value.email,
    //         password: passwordHash,
    //       },
    //     })
    //     .subscribe(
    //       (respuesta) => {
    //         console.log("Usuario Creado");
    //         this.registerOK = true;
    //         this.route.navigate(["/thankyou-page-user"]);
    //       },
    //       (err) => {
    //         console.warn(err);
    //         this.emailRegistrado = true;
    //       }
    //     );*/
    // }
    RegisterUserComponent.prototype.next = function (step) {
        var _this = this;
        //console.log( this.formData );
        this.isLoading = true;
        var params = new HttpParams()
            .set("email", this.formData.value.email)
            .set("telefono", this.formData.value.celular);
        this.submitted = true;
        if (step === 1) {
            if (!this.formData.valid) {
                this.isLoading = false;
                return;
            }
            /**** Agregado */
            this._api
                .validateUser(params)
                .subscribe(function (data) {
                _this.step += 1;
                _this.isLoading = false;
            }, function (err) {
                _this.emailRegistrado = true;
                _this.isLoading = false;
            });
            /***** ---------------- */
            //this.step += 1; //Comentado
        }
        if (step === 2) {
            if (!this.formDataEmail.valid) {
                return;
            }
            else if (this.g.password.value !== this.g.retypePassword.value) {
                return;
            }
            else {
                this.createCustomer();
                //this.send();
            }
        }
    };
    RegisterUserComponent.prototype.inputValidator = function (event) {
        this.emailRegistrado = false;
    };
    return RegisterUserComponent;
}());
export { RegisterUserComponent };
/*
const password = this.formData.value.password;
      let passwordHash = bcrypt.hash(password, 10);
      console.log(password);
      console.log(passwordHash);
      console.log(passwordHash._zone_symbol__value);


      this._api.genericGetNew('https://apiv2.kamgus.com/api/v2/users').subscribe( (users:any) => {
        this.correos = users.data;
        console.log(this.correos);
        this.emailRegistrado = this.correos.filter(e => e.email==this.formDataEmail.value.email)
        console.log(this.emailRegistrado);
      });

*/
