import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-incrementador',
  templateUrl: './incrementador.component.html',
  styles: []
})
export class IncrementadorComponent implements OnInit {

  @Input() leyenda = 'leyenda';
  @Input() progress:number = 50;
  
  @Output() changeProgress: EventEmitter<number> = new EventEmitter();


  constructor() {

    console.log('Leyenda',this.leyenda);
    console.log('Progreso',this.progress);

  }

  ngOnInit() {
  }

  barra( number ){
   
    if( this.progress >= 100 ){
      this.progress = 100;
      return;
    }
    if( this.progress <= 0 ){
      this.progress = 0;
      return;
    }

    this.progress = this.progress + number;
    
    this.changeProgress.emit(this.progress);

  }

}
