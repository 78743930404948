import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SharedService } from '../services/shared/shared.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router, 
    private sharedService: SharedService
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // let currentUserType = null;
    // currentUserType = JSON.parse(localStorage.getItem('token'));
    // currentUserType != null
    if (localStorage.getItem('token')) {
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
