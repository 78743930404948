/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./intro-apps.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./intro-apps.component";
var styles_IntroAppsComponent = [i0.styles];
var RenderType_IntroAppsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntroAppsComponent, data: {} });
export { RenderType_IntroAppsComponent as RenderType_IntroAppsComponent };
export function View_IntroAppsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "row cont-login login-user"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "col-md-6 pd-forms-login text-center no-display-movil"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "img-80"], ["src", "../../assets/images/logo-white2.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h1", [["class", "color-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "b", [["style", "font-size: 37px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["BIENVENIDO"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [["class", "color-white p-login"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      Te damos la bienvenida a la mejor red para solicitar servicios de\n      acarreos, mudanzas y log\u00EDstica en Panam\u00E1. Ingresa y disfruta de las\n      soluciones que ofrece Kamgus\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "col-md-6 cont-white pd-forms-login"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    \n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_IntroAppsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-intro-apps", [], null, null, null, View_IntroAppsComponent_0, RenderType_IntroAppsComponent)), i1.ɵdid(1, 114688, null, 0, i2.IntroAppsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntroAppsComponentNgFactory = i1.ɵccf("app-intro-apps", i2.IntroAppsComponent, View_IntroAppsComponent_Host_0, {}, {}, []);
export { IntroAppsComponentNgFactory as IntroAppsComponentNgFactory };
