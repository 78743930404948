var SettingsService = /** @class */ (function () {
    function SettingsService(_document) {
        this._document = _document;
        this.ajustes = {
            temaUrl: 'assets/css/colors/blue.css',
            tema: 'blue'
        };
        this.cargarAjustes();
    }
    SettingsService.prototype.guardarAjustes = function () {
        // console.log('Guardado en el localStorage');
        localStorage.setItem('ajustes', JSON.stringify(this.ajustes));
    };
    SettingsService.prototype.cargarAjustes = function () {
        if (localStorage.getItem('ajustes')) {
            this.ajustes = JSON.parse(localStorage.getItem('ajustes'));
            // console.log( 'Cargando del localstorage' );
            this.aplicarTema(this.ajustes.tema);
        }
        else {
            // console.log( 'Usando valores por defecto' );
            this.aplicarTema(this.ajustes.tema);
        }
    };
    SettingsService.prototype.aplicarTema = function (tema) {
        var url = "assets/css/colors/" + tema + ".css";
        this._document.getElementById('tema').setAttribute('href', url);
        this.ajustes.tema = tema;
        this.ajustes.temaUrl = url;
        this.guardarAjustes();
    };
    return SettingsService;
}());
export { SettingsService };
