import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { tap } from "rxjs/operators";
var apikamgus_base_url = environment.apikamgus_base_url;
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
    }
    Object.defineProperty(ApiService.prototype, "token", {
        get: function () {
            return 'Bearer ' + JSON.parse(localStorage.getItem('token')) || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiService.prototype, "headers", {
        get: function () {
            return {
                headers: {
                    'Authorization': this.token
                }
            };
        },
        enumerable: true,
        configurable: true
    });
    ApiService.prototype.genericDelete = function (url, withHeaders) {
        if (withHeaders === void 0) { withHeaders = ""; }
        if (withHeaders === "TOV2") {
            return this.http.delete(environment.apikamgus_base_url + url, this.headers);
        }
        return this.http.delete(environment.apikamgus_base_url + url, this.headers);
    };
    ApiService.prototype.genericPut = function (url, params, withHeaders) {
        if (withHeaders === void 0) { withHeaders = ""; }
        if (withHeaders === "TOV2") {
            return this.http.put(environment.apikamgus_base_url + url, params, this.headers);
        }
        return this.http.put(environment.apikamgus_base_url + url, params, this.headers);
    };
    ApiService.prototype.genericPost = function (url, params, withHeaders) {
        if (withHeaders === void 0) { withHeaders = ""; }
        if (withHeaders === "TOV2") {
            return this.http.post(environment.apikamgus_base_url + url, params, this.headers);
        }
        return this.http.post(environment.apikamgus_base_url + url, params, this.headers);
    };
    ApiService.prototype.genericPostNew = function (url, params) {
        return this.http.post(url, params);
    };
    ApiService.prototype.genericGet = function (url, withHeaders) {
        if (withHeaders === void 0) { withHeaders = ""; }
        if (withHeaders === "TOV2") {
            return this.http.get(environment.apikamgus_base_url + url, this.headers);
        }
        return this.http.get(environment.apikamgus_base_url + url, this.headers);
    };
    ApiService.prototype.genericGetNew = function (url) {
        return this.http.get(environment.genericDos + url);
    };
    ApiService.prototype.licences = function () {
        return this.http.get(environment.licences);
    };
    ApiService.prototype.licencesModules = function (licence) {
        return this.http.post(environment.licencesModules, licence);
    };
    ApiService.prototype.validateLicence = function (licence) {
        return this.http.post(environment.validateLicence, licence);
    };
    ApiService.prototype.activateFreeLicence = function (licence) {
        return this.http.post(environment.activateFreeLicence, licence);
    };
    ApiService.prototype.getLicenceByUser = function (licence) {
        return this.http.post(environment.getLicenceByUser, licence);
    };
    ApiService.prototype.preAuthorization = function (licence) {
        return this.http.post(environment.payCard, licence);
    };
    /** API Generic Enviroment V2 */
    ApiService.prototype.genericPostV2 = function (url, params) {
        return this.http.post(environment.genericDos + url, params);
    };
    ApiService.prototype.validateUser = function (params) {
        var url = apikamgus_base_url + "/verification/email";
        return this.http.post(url, params).pipe(tap(function (resp) {
            return resp;
        }));
    };
    // registrar usuario con apikamgus v2
    ApiService.prototype.registerCustomer = function (params) {
        var url = apikamgus_base_url + "/registration/customers";
        return this.http.post(url, params).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.registerDriver = function (params) {
        var url = apikamgus_base_url + "/registration/drivers";
        return this.http.post(url, params, {
            reportProgress: true,
        }).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.deleteAccount = function (params) {
        var url = apikamgus_base_url + "/driver/delete_account";
        return this.http.post(url, params, {
            reportProgress: true,
        }).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.licenceModules = function () {
        var url = apikamgus_base_url + "/license/modules";
        return this.http.get(url, this.headers).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.loadCountries = function () {
        var url = apikamgus_base_url + "/countries";
        return this.http.get(url, this.headers).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.updateProfileImage = function (imagen) {
        var url = apikamgus_base_url + "/update/user/profile/image";
        var formData = new FormData();
        formData.append('image', imagen);
        return this.http.post(url, formData, this.headers).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.updateProfile = function (params) {
        var url = apikamgus_base_url + "/update/user/profile";
        return this.http.put(url, params, this.headers).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.getAllCategories = function () {
        var url = apikamgus_base_url + "/categories";
        return this.http.get(url).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.getCategoriesOfASubcategory = function (id) {
        var url = apikamgus_base_url + "/categories/" + id + "/subcategories";
        return this.http.get(url).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.getAllArticles = function () {
        var url = apikamgus_base_url + "/articles";
        return this.http.get(url).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.getAllSubCategories = function () {
        var url = apikamgus_base_url + "/subcategories";
        return this.http.get(url).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.getCreditsCard = function () {
        var url = apikamgus_base_url + "/payment/methods";
        return this.http.get(url, this.headers).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.getSeti = function () {
        var url = apikamgus_base_url + "/setup/intent";
        return this.http.post(url, {}, this.headers).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.servicexArticle = function (params) {
        var url = apikamgus_base_url + "/servicesxarticles";
        return this.http.post(url, params, this.headers).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.getVehicleTypes = function () {
        var url = apikamgus_base_url + "/typestransports";
        return this.http.get(url).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.addCards = function (params) {
        var url = apikamgus_base_url + "/new/payment/method";
        return this.http.post(url, params, this.headers).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.forgotPassword = function (email) {
        var url = apikamgus_base_url + "/forgot/password?email=" + email;
        return this.http.get(url).pipe(tap(function (resp) {
            return resp;
        }));
    };
    ApiService.prototype.resetPassword = function (params) {
        var url = apikamgus_base_url + "/reset/password";
        return this.http.post(url, params).pipe(tap(function (resp) {
            return resp;
        }));
    };
    return ApiService;
}());
export { ApiService };
