var Graficas1Component = /** @class */ (function () {
    function Graficas1Component() {
        this.doughnutChartLabels = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
        this.doughnutChartData = [350, 450, 100];
        this.doughnutChartType = 'doughnut';
        this.graficos = {
            'grafico1': {
                'labels': ['Con Frijoles', 'Con Natilla', 'Con tocino'],
                'data': [24, 30, 46],
                'type': 'doughnut',
                'leyenda': 'El pan se come con'
            },
            'grafico2': {
                'labels': ['Hombres', 'Mujeres'],
                'data': [4500, 6000],
                'type': 'doughnut',
                'leyenda': 'Entrevistados'
            },
            'grafico3': {
                'labels': ['Si', 'No'],
                'data': [95, 5],
                'type': 'doughnut',
                'leyenda': '¿Le dan gases los frijoles?'
            },
            'grafico4': {
                'labels': ['No', 'Si'],
                'data': [85, 15],
                'type': 'doughnut',
                'leyenda': '¿Le importa que le den gases?'
            },
        };
    }
    Graficas1Component.prototype.ngOnInit = function () {
    };
    return Graficas1Component;
}());
export { Graficas1Component };
