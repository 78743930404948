export enum Modules {
    Services = 'services',
    History = 'history',
    Wallet = 'wallet',
    Payment = 'payment-center',
    Profile = 'profile',
    Referrals = 'referrals',
    Store = 'store',
    Massive = 'massive',
    Support = 'support',
    Admin = 'admin'
}
