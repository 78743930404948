import { HttpParams } from '@angular/common/http';
import { SharedService } from '../../services/shared/shared.service';
import { ApiService } from '../../services/api/api.service';
var BuyLicenceComponent = /** @class */ (function () {
    function BuyLicenceComponent(_api, sharedService) {
        this._api = _api;
        this.sharedService = sharedService;
    }
    BuyLicenceComponent.prototype.ngOnInit = function () {
        this.user = this.sharedService.decodeJwt();
        console.log(this.user);
        this.loadLicences();
        this.loadLicence();
    };
    BuyLicenceComponent.prototype.loadLicences = function () {
        var _this = this;
        this._api.licences().subscribe(function (lic) {
            lic['licences'].forEach(function (element) {
                var setA = JSON.parse(element.atributes);
                element.setAttributes = setA;
            });
            _this.licences = lic['licences'];
            console.log(_this.licences);
        });
    };
    BuyLicenceComponent.prototype.loadLicence = function () {
        var _this = this;
        var params = new HttpParams()
            .set('licence', localStorage.getItem('licence'));
        this._api.validateLicence(params).subscribe(function (ok) {
            _this.actual = ok['licences'][0];
            console.log(_this.actual);
        });
    };
    BuyLicenceComponent.prototype.activateLicence = function (item) {
        var _this = this;
        var params = new HttpParams()
            .set('id_user', this.user.idusuarios)
            .set('id_licence', item.id);
        this._api.activateFreeLicence(params).subscribe(function (licence) {
            var paramss = new HttpParams()
                .set('id_user', _this.user.idusuarios);
            _this._api.getLicenceByUser(paramss).subscribe(function (lic) {
                localStorage.setItem('licence', lic['licences'][0].licence);
                _this.loadLicence();
            });
        });
    };
    return BuyLicenceComponent;
}());
export { BuyLicenceComponent };
