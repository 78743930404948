import { Router } from '@angular/router';
import { SharedService } from '../services/shared/shared.service';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, sharedService) {
        this.router = router;
        this.sharedService = sharedService;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        // let currentUserType = null;
        // currentUserType = JSON.parse(localStorage.getItem('token'));
        // currentUserType != null
        if (localStorage.getItem('token')) {
            return true;
        }
        else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    };
    return AuthGuard;
}());
export { AuthGuard };
