<div class="row text-center" *ngIf="actual !== undefined">
  <div class="col-md-12 text-center">
    <h4 class="actual-licence"><small>Su licencia actual es: </small>{{ actual.name }}</h4>
    <h4>Desde: {{ actual.createdAtUser | date : 'short' }}</h4>
  </div>
</div>

<div class="row" *ngIf="licences">
  <div class="col-md-4" *ngFor="let item of licences" [hidden]="actual && actual.id_licence  ? actual.id_licence === item.id_licence : ''">
    <div class="card" >
      <img class="card-img-top" src="{{item.img}}" alt="Card image cap">
      <div class="card-body">
        <h4 class="card-title">{{ item.title }}</h4>
        <ul class="non-style">
          <li class="card-text" *ngFor="let att of item.setAttributes ">
            <i class="mdi mdi-check" style="color: green;" *ngIf="att.active"></i>
            <i class="mdi mdi-close" style="color: red;"  *ngIf="!att.active"></i>
            
            {{att.item}}
          </li>
        </ul>
        <h4 class="price"> <span *ngIf="item.price !== 'FREE' ">$</span> {{ item.price }}</h4>
        <a  class="btn btn-primary btn-block" style="color: #fff;" (click)="activateLicence( item )">Adquirir</a>
      </div>
    </div>
  </div>
</div>

