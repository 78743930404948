import { SettingsService } from "./services/service.index";
var AppComponent = /** @class */ (function () {
    function AppComponent(_ajustes) {
        this._ajustes = _ajustes;
        this.title = "MyApp";
        this.theme = this._ajustes.ajustes.temaUrl;
    }
    return AppComponent;
}());
export { AppComponent };
