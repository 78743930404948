import { environment } from '../../../environments/environment';
var ThankyouPageDriverComponent = /** @class */ (function () {
    function ThankyouPageDriverComponent() {
    }
    ThankyouPageDriverComponent.prototype.ngOnInit = function () {
        this.web = environment.web;
        this.google = environment.google;
        this.ios = environment.ios;
    };
    return ThankyouPageDriverComponent;
}());
export { ThankyouPageDriverComponent };
