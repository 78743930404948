import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsService , SharedService, SidebarService} from './service.index';
import { ApiService } from './api/api.service';
import { EmmitersService } from './shared/emmiters.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    SettingsService,
    SharedService,
    SidebarService,
    ApiService,
    EmmitersService
  ],
})
export class ServiceModule { }
