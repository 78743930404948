import { RoleModalModule } from './../components/admin/role-modal/role-modal.module';
import { RoleModalComponent } from './../components/admin/role-modal/role-modal.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcumsComponent } from './breadcums/breadcums.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { Image_userPipe } from '../pipes/image_user.pipe';
import { FooterComponent } from './footer/footer.component';
import { PastelGraficoComponent } from '../components/graficos/pastel-grafico/pastel-grafico.component';
import { LineaGraficoComponent } from '../components/graficos/linea-grafico/linea-grafico.component';
import { PolarGraficoComponent } from '../components/graficos/polar-grafico/polar-grafico.component';
import { ImageInputComponent } from '../components/image-input/image-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { SelectDriverModalComponent } from '../modals/select-driver-modal/select-driver-modal.component';


@NgModule({
    declarations:[
        NopagefoundComponent,
        HeaderComponent,
        SidebarComponent,
        BreadcumsComponent,
        FooterComponent,
        Image_userPipe,
        PastelGraficoComponent,
        LineaGraficoComponent,
        PolarGraficoComponent,
        ImageInputComponent,
        
        //modals
        //SelectDriverModalComponent,
    ],
    exports:[
        NopagefoundComponent,
        HeaderComponent,
        SidebarComponent,
        FooterComponent,
        BreadcumsComponent,
        PastelGraficoComponent,
        LineaGraficoComponent,
        PolarGraficoComponent,
        ImageInputComponent,
        //SelectDriverModalComponent,
    ],
    imports:[
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ]
})

export class SharedModule { }