<div class="text-center pd-thank-you">
  <br /><br />
  <h2 class="color-orange | titulo-thnaks">
    GRACIAS POR SER PARTE <br />
    DE KAMGUS
  </h2>
  <br />
  <img
    src="../../assets/images/thanks.png"
    style="padding-bottom: 40px; width: 31%"
  />
  <br />
  <p style="color: #000; font-size: 20px">
    Nuestro servicio se especializa en esta de la mano con el <br />
    cliente,
  </p>
  <br />
  <h4 class="color-orange | text-thanyou__yapuedes">
    YA PUEDES UTILIZAR KAMGUS
  </h4>
  <!--APP CARDS-->
  <div class="row mt-3 ocultar2 contenedorApp contenedorAppResposive | espacio-division">
    <div class="col-md-6 text-right | responsive-thank">
      <a href="https://play.google.com/store/apps/details?id=com.kamgusu.pa">
        <img src="../../../assets/images/google.png" style="height: 40px" />
      </a>
    </div>
    <div class="col-md-6 text-left | responsive-thank">
      <a [href]="ios">
        <img src="../../../assets/images/ios.png" style="height: 40px" />
      </a>
    </div>
  </div>
  <!--APP CARDS TELEFONO-->
  <div class="row ocultar">
    <div class="col-md-8 mb-3 mt-3">
      <a href="https://play.google.com/store/apps/details?id=com.kamgusu.pa">
        <img src="../../../assets/images/google.png" style="height: 40px" />
      </a>
    </div>
    <div class="col-md-8 mb-3">
      <a [href]="ios">
        <img src="../../../assets/images/ios.png" style="height: 40px" />
      </a>
    </div>
  </div>
  
   <!--APP CARDS TELEFONO-->
  <br />
  <!--Botones-->
  <div class="container-fluid contenedorBtn ocultar2">
    <div
      class="row | justify-content-center"
      style="padding: 0% 6% 0% 6%; margin: 0% 6% 0% 6%; display: block ruby"
    >
      <div class="responsive-thank__btn btnmarginright"  style="margin-left: 60px;">
        <button
          class="col-sm-14 | btn btn-block btn-warning btn-rounded | btn-thanks__login"
          routerLink="/login"
        >
          Inicia Sesion Aquí
        </button>
      </div>
      <!-- <div class="responsive-thank__btn btnmarginleft">
        <a
          [href]="web"
          class="col-sm-14 | btn btn-facebook--thnksyou btn-lg btn-block"
          style="color: #fff"
        >
          Volver a página web
        </a>
      </div>    -->
  </div>
</div>
<!--BOTONES-->
<!--Botones responsive-->
<div class="container-fluid ocultar">
  <div
    class="row"
  >
    
      <button
        class="col-md-6 mb-3 btn btnoranje"
        routerLink="/login"
      >
        Inicia Sesion Aquí
      </button>
    
    
      <button
        class="col-md-6  mb-3 btn btnblue"
      >
      <a
        href="https://apps.apple.com/us/app/id1495896386"
        style="color: #fff"
      >
        Volver a página web
      </a>
      </button>
    
  
</div>
</div>
<!--Botones responsive-->
<!--Responsive

  <div class="container contenedorBtnMovil ">
   
      <div class="responsive-thank__btn">
        <button
          routerLink="/login"
        >
          Inicia Sesion Aquí
        </button>
      </div>
      <div class="responsive-thank__btn">
        <a
          [href]="web"
          style="color: #fff"
        >
          Volver a página web
        </a>
      </div>
    
  
</div>
-->








  <!--
  <div class="container-fluid contenedorBtn ">
    <div
      class="row | justify-content-center"
      style="padding: 0% 6% 0% 6%; margin: 0% 6% 0% 6%; display: block ruby"
    >
      <div class="responsive-thank__btn">
        <button
          class="col-sm-14 | btn btn-block btn-warning btn-rounded | btn-thanks__login"
          routerLink="/login"
        >
          Inicia Sesion Aquí
        </button>
      </div>
      <div class="responsive-thank__btn">
        <a
          [href]="web"
          class="col-sm-14 | btn btn-facebook--thnksyou btn-lg btn-block"
          style="color: #fff"
        >
          Volver a página web
        </a>
      </div>
    
  </div>
</div>
-->